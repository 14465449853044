import styled from "styled-components";
import Button from "../../../components/Button";
import { MOBILE_BREACKPOINT } from "../../../utils/breakpoints";

export const MobileContainer = styled.div`
  height: 100vh;
  max-width: ${({ isMobile }) => (isMobile ? "320px" : "auto")};
  margin-top: ${({ isMobile }) => (isMobile ? "9rem" : "0")};
  margin-bottom: ${({ isMobile }) => (isMobile ? "1rem" : "0")};
  display: flex;
  justify-content: center;
  background-color: #e0d6da;
  width: 100%;
  display: flex;
  align-items: center;
  scroll-snap-align: start;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
    scroll-snap-align:initial;
  }
`;

export const Image = styled.img`
    width: 100%;
    max-width: 600px;
    margin-left: 2rem;
`

export const DownloadTextContainer = styled.div`
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

export const DownloadButtonsContainer = styled.div`
    display: flex;
    margin-top: 3rem;
    width: 400px;
    justify-content: space-between;
`

export const SoftwareIcon = styled.img`
    width: 32px;
    margin-right: 13px;
`

export const DownloadButtonContent = styled(Button)`
    display: flex;
    align-items: center;
    font-family: "Merriweather";
`