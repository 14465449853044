import React, { useEffect, useState } from "react";
import TextViewWrapper from "../../components/TextView";
import backgroundAboutUs from "../../assets/image/backgroundAboutUs.png";
import { LandingHeader } from "../Landing/FirstVideo";
import {
  PolicyContainer,
  PolicyLink,
  PolicysNavigation,
  PolicyText,
  PolicyTitle,
  Numeration,
  LineBreak,
} from "./styles";
import styled from "styled-components";
import { FlexBox } from "../../components/Box/stlye";
import { useTranslation } from "react-i18next";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";
import { Link, AHref } from "../../components/Anchor/styles";

const Center = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled(LandingHeader)`
  font-size: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 2.5em;
  }
`;

export default function PyP() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("Bitennial - Privacy Policy");
  }, []);

  return (
    <TextViewWrapper>
      <PolicyContainer>
        <PolicyTitle>THIS PRIVACY POLICY</PolicyTitle>
        <PolicyText>
          {t(
            "This Privacy Policies, together with our Terms and Conditions (which can be found at "
          )}
          <Link style={{ color: "#5633f1" }} to="/terms-and-conditions">
            {t("Terms & Conditions")}
          </Link>
          {t(
            " and are incorporated here by reference) describe the types of personaldata that [DENOMINACIÓN DE BITENNIAL] (hereinafter, “Bitennial”, the “Company”, “We”, “Us” or “Our”, indistinctly) collects through the -website www.bitennial.com and our mobile application (hereinafter, the “Platform”), how we use user data, to whom we communicate them and the rights and options that we make available to the users of our Platform regarding the use we make of their personal data and the exercise of the rights of the owners of the data. We also describe the measures implemented to protect the security of personal data collected and how users can contact us to learn about our privacy practices. The user of the Platform expressly, informed, voluntarily and unequivocally consents to the inclusion of the personal data provided to the Company in its databases, as well as to the processing of their personal data by automated and non-automated means for the purposes described in the following sections of this document and subject to the terms and conditions set forth herein. By accessing, connecting or using the Platform in any other way, you confirm that you have read, understood and accepted these provisions regarding the Company’s privacy policies. In case you do not agree with these privacy policies, refrain from accessing the Platform and using it in any way. When we ask for specific personal information from Users, it is because we are required by law to process this information, or it is relevant for particular purposes. If you choose not to provide us with this information, we won't be able to offer our Services. Any non-required information you provide to us is done so voluntarily. You decide whether to provide us with this non-required"
          )}
          <br /> {t("information or not.")}
        </PolicyText>
        <PolicyTitle>{t("Consent")}</PolicyTitle>
        <PolicyText>
          {t("You, as the holder of personal data, acknowledge and agree:")}
        </PolicyText>
        <PolicyText>
          <strong>(a)</strong>{" "}
          {t("That you are legally an adult according to your country's laws")}
          <LineBreak />
          <strong>(b)</strong>{" "}
          {t(
            "To freely give us your consent to process your personal data to provide you with the Bitennial Services"
          )}
          <LineBreak />
          <strong>(c)</strong>{" "}
          {t("That this Privacy Policy has been made known to you")}
          <LineBreak />
          <strong>(d)</strong>{" "}
          {t(
            "To you have read, understood and agreed to the terms set out in this Privacy Policy."
          )}
        </PolicyText>
        <PolicyTitle>{t("Information Bitennial Processes")}</PolicyTitle>
        <PolicyText>
          {t(
            " We want you to understand the types of information we process when you register for and use Bitennial’s Services (hereinafter the “Personal Data”)."
          )}
          <LineBreak />
          {t(
            "1. Information you provide to us at registration: When you create an Account at our Platform, you provide us with personal information that includes your identification data such as:"
          )}
          <LineBreak />
          {t(
            "When you create an Account at our Platform, you provide us with personal information that includes your identification data such as:"
          )}
          <ul>
            <li>{t("Full Name and Surname without abbreviation")}</li>
            <li>{t("Date of birth")}</li>
            <li>{t("Gender")}</li>
            <li>{t("Birth state and country")}</li>
            <li>{t("Nationality")}</li>
            <li>{t("Username and password")}</li>
            <li>{t("E-mail address")}</li>
            <li>{t("Full personal address and zip code")}</li>
            <li>{t("[Financial and wealth data]")}</li>
            <li>{t("[Employment data (except trade union membership)]")}</li>
            <li>{t("Mobile phone number")}</li>
            <li>
              {t(
                "Google authenticator to be used for 2FA verification for improved security."
              )}
            </li>
            <li>
              {t(
                "Geolocation of the mobile device, or the IP address of the computer, from which you opened your Account;"
              )}
            </li>
            <li>{t("A valid official ID")}</li>
            <li>{t("Proof of Address")}</li>
          </ul>
          <LineBreak />
          {t("2. Information on special categories of Personal Data")}
          <LineBreak />{" "}
          {t(
            "Regarding the due functioning and to apply the proper security measures to your Account, we process your personal data relating to physical characteristics and biometrics, such as facial recognition or fingerprints. As this information is considered as a special category of personal data, we need your explicit consent to process this information."
          )}
          <LineBreak />{" "}
          {t(
            "Your express consent to process your biometric data will be interpreted if you choose to use the “biometrics security” option in our Platform. We do not handle any other particular category of personal data without your express consent."
          )}
          <LineBreak />
          {t("3. Information we collect as you use our Services")}
          <LineBreak />
          {t(
            "3.1. Through your use of the Platform and our different Services, we also monitor and obtain tracking information related to usages such as:"
          )}
          <ul>
            <li>{t("Access date & time")}</li>
            <li>{t("Device identification")}</li>
            <li>{t("Operating system")}</li>
            <li>{t("Browser type")}</li>
            <li>{t("IP address.")}</li>
          </ul>
          {t(
            "A processor collects this information under our supervision. This Service usage data helps us our systems to ensure that our interface is accessible for Users across all platforms and can aid during criminal investigations."
          )}
          <LineBreak />
          {t("3.2. Transaction information")}
          <LineBreak />
          {t(
            "For all our User’s accounts, we collect transaction information, including deposit snapshots, Account balances, trade history,  withdrawals, order activity, and distribution history. This transaction data is monitored for suspicious trading activity for user fraud protection and legal case resolution."
          )}
        </PolicyText>
        <PolicyTitle>{t("Treatment of Personal Data")}</PolicyTitle>
        <PolicyText>
          {t("The data we collect can be used for the following purposes:")}
          <ul>
            <li>{t("Communicate with you")}</li>
            <li>
              {t(
                "Send you our newsletter, which includes promotional materials and other communications"
              )}
            </li>
            <li>{t("Answer your questions")}</li>
            <li>
              {t(
                "Protect our Users against automated abuse such as spam, phishing, and Distributed Denial of Service (DDoS) attacks"
              )}
            </li>
            <li>
              {t(
                "Customize preferences and visits to our Platform and offer content tailored to your interests and the way you navigate and interact with our Platform"
              )}
            </li>
            <li>
              {t(
                "To measure our Platform performance and resolve issues with the User experience"
              )}
            </li>
            <li>
              {t("Operate, evaluate and improve our products and Services")}
            </li>
            <li>
              {t(
                "To enforce our Terms and Conditions and other agreements and policies"
              )}
            </li>
            <li>
              {t(
                "Comply with and enforce the applicable legal requirements, the corresponding industry standards, contractual obligations and our policies."
              )}
            </li>
          </ul>
          {t(
            "In the case that we are going to use your personal data differently from the one described above, we will communicate it in a timely manner."
          )}
          <LineBreak />
          {t(
            "Through our Platform, we can also use third-party analytical services. The web analysis service providers that manage these services use technologies such as cookies, network server logs and web beacons that help us analyze the use that users make of our Platform. The information collected by these means (including the IP address) may be brought to the attention of the providers of such analytical services as well as other third parties that will make use of the information collected to, among other purposes, evaluate the use of the Platform."
          )}
        </PolicyText>
        <PolicyTitle>{t("Cookies Policy")}</PolicyTitle>
        <PolicyText>
          {t(
            "Cookies are text files that are automatically downloaded and stored on the hard drive of the user's computer when browsing a specific website. They allow the Internet server to remember some data about the user, including the user's preferences for viewing the pages on that server, username, and password."
          )}
          <LineBreak />
          {t(
            "Bitennial informs you that it uses cookies on the Site to obtain personal information. In case you do not agree with the use of this technology, please disable the cookies from your browser or do not use our Site."
          )}
        </PolicyText>
        <PolicyTitle>{t("Information We Share")}</PolicyTitle>
        <PolicyText>
          {t(
            "The personal data of the Users that we collect through the Platform may be disclosed to third parties only in accordance with what is described in these privacy policies. Personal data collected through the Platform may be communicated to our subsidiaries and associated entities for the purposes described above. We will also communicate such personal data to service providers that provide services on our behalf in accordance with our instructions and for the purposes detailed in these Privacy Policies. We do not authorize such service providers to disclose or disclose the personal data of Users except for those uses that are strictly necessary for the provision of the services or to comply with legal obligations."
          )}
          <LineBreak />
          {t(
            "The Company understands the importance of maintaining the confidentiality of all data that could be collected. Consequently, the Company undertakes, in order to guarantee such confidentiality, to sign confidentiality agreements with each of the parties that carry out the processing of personal data. The Company and those involved in any phase of the processing of personal data are bound to professional secrecy regarding them."
          )}
          <LineBreak />
          {t(
            "Likewise, we may disclose your personal data (i) if we are obliged to do so by law or within the framework of a legal procedure, (ii) to the security forces and bodies or other public officials when they legitimately request it, (iii) when we consider that such disclosure is necessary or convenient to avoid physical damage or economic loss, (iv) in the context of an investigation of fraudulent or illegal activities, or that fraudulent or illegal activities are presumed or (v) in case of sale or transfer of the totality or a part of our company or our assets (including cases of restructuring, dissolution or liquidation)."
          )}
          <LineBreak />
          {t(
            "Also, the Users' information may be communicated or transferred to a third party, in the course of a corporate transaction, including a merger or an asset sale or an acquisition, always in the best interests of the users."
          )}
          <LineBreak />
          {t(
            "We may disclose your personal data when permitted by the applicable law or when it is express consent by you to perform the transfer."
          )}
        </PolicyText>
        <PolicyTitle>{t("International Data Transfer")}</PolicyTitle>
        <PolicyText>
          {t(
            "Your personal data collected through the Platform may be transferred for the purposes described above to entities located in other countries. These countries may not have the same data protection regulations as in your country. In this regard, we inform you that we have implemented safeguards that guarantee an adequate level of protection of personal data when they are transferred to other jurisdictions. These safeguards include contractual clauses or commitments aimed at imposing compliance with the applicable and its complementary regulations. In any event, when using the services of the Platform you are aware of the international transfer of your personal data as indicated in this section."
          )}
        </PolicyText>
        <PolicyTitle>{t("Security of your Personal Data")}</PolicyTitle>
        <PolicyText>
          {t(
            "We implement administrative, technical and physical security measures designed to protect your personal data against destruction, loss, alteration, access, communication or accidental, illegal or unauthorized use."
          )}
          <LineBreak />
          {t(
            "Thus, to prevent unauthorized access, maintain the accuracy of the data and ensure the correct use of the personal data that it provides to us, the Company has put into use certain physical, electronic, administrative and security procedures. We safeguard personal data according to established security standards and procedures and continuously evaluate new technology to protect that information."
          )}
          <LineBreak />
          {t(
            "However, you acknowledge that the existing technical means that provide security are not impregnable and that even when all reasonable security precautions are adopted it is possible to suffer manipulation, destruction and / or loss of information. If you suspect that your personal information has been compromised, especially Account and/or password information, please lock your Account and contact Bitennial’s customer service immediately."
          )}
        </PolicyText>
        <PolicyTitle>{t("Link to other Websites")}</PolicyTitle>
        <PolicyText>
          {t(
            "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit."
          )}
          <LineBreak />
          {t(
            "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
          )}
        </PolicyText>
        <PolicyTitle>
          {t("Procedure for Exercising Privacy Rights")}
        </PolicyTitle>
        <PolicyText>
          {t(
            "You have, at all times, the right to know what personal data we have about you, what we use it for, and the conditions of use we give to it (Access). Likewise, it is your right to request the correction of your personal information if it is outdated, inaccurate, or incomplete (Correction); that we remove it from our records or databases when you consider that it is not being used per the principles, duties, and obligations provided for in the regulations (Cancellation or right to be forgotten); you also have the right to receive your personal data provided to Bitennial in a structured, commonly used and machine-readable format and, where technically feasible, you have the right to instruct us to transmit those data to another controller without hindrance; as well as to oppose and object the use of your personal data for specific purposes (Opposition). These rights are known as Privacy Rights."
          )}
          <LineBreak />
          {t(
            "To exercise any of the Privacy Rights, you must send an e-mail to contact@bitennial.com, which must contain the following information and documentation:"
          )}
          <LineBreak />
          <strong>(a)</strong>{" "}
          {t(
            "Your name and address, or other means to communicate the answer to your request"
          )}
          <LineBreak />
          <strong>(b)</strong>{" "}
          {t(
            "The valid documents proving your identity (simple copy in printed or electronic format of your ID or passport) or, if applicable, a simple copy in printed or electronic form of the power of attorney of the person that will represent you, and their corresponding ID or passport"
          )}
          <LineBreak />
          <strong>(c)</strong>{" "}
          {t(
            "A clear and precise description of the data on which you are seeking to exercise any of the Privacy Rights"
          )}
          <LineBreak />
          <strong>(d)</strong>{" "}
          {t(
            "Any other element or document that facilitates the location of your personal data."
          )}
          <LineBreak />
          {t(
            "In the case of requests for the rectification of personal data, you must also indicate the modifications to be made and provide the documentation supporting your application. To comply with the obligation of access to personal data, it will be done after authenticating your identity, making the information available on site at Bitennial's offices (other ways can be agreed between you and Bitennial whenever the requested information allows it)."
          )}
          <LineBreak />
          {t(
            "The contact details of the person or department that will process the requests for the exercise of the Privacy Rights, as well as to respond to any doubt that you could have concerning the treatment of your information."
          )}
        </PolicyText>
        <PolicyTitle>{t("Changes to this Privacy Policy")}</PolicyTitle>
        <PolicyText>
          {t(
            "These privacy policies may be subject to periodic updating and without prior notice to reflect the changes introduced in our personal data practices. In the case of substantial changes, we will notify you by a visible notice on our Platform or by sending an email to the address declared at the time of creating your user Account, indicating at the bottom of the privacy policies the date of Your last update. Any modification of these privacy policies will take effect from the moment of its publication on the Platform. Your interaction with us after the introduction of these modifications will mean that you have accepted those modifications. Bitennial encourages you to review our Privacy Policy periodically. We will ask for your consent before using your information for any purpose that is not covered in this Privacy Policy."
          )}
        </PolicyText>
        <PolicyTitle>{t("Contact Us")}</PolicyTitle>
        <PolicyText>
          {t(
            "Users can contact us to update their data or exercise their rights in accordance with these privacy policies by sending an email to contact@bitennial.com or by visiting this page on our website: https://bitennial.com/en/contact.com"
          )}
        </PolicyText>
      </PolicyContainer>
    </TextViewWrapper>
  );
}
