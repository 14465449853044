import { motion } from "framer-motion";
import styled from "styled-components";

export const LoadingContainer = styled(motion.div)`
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #424141;
`;

export const LoadingAnimation = styled(motion.div)`
  width: 40px;
  height: 40px;
  background-color: #5633f1;
  clip-path: circle(50% at 50% 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const InnerCircle = styled.div`
  background-color: 999999;
  clip-path: circle(50% at 50% 50%);
  width: 10px;
  height: 10px;
`;