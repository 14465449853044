import styled from "styled-components";
import Button from "../../components/Button";
import {MOBILE_BREACKPOINT} from "../../utils/breakpoints"
import "typeface-roboto";


export const SimulateButton = styled(Button)`
    width: 60%;
    max-width: 368px;
    border-radius: 16px;
    height: 56px;
    font-family: 'Roboto';
`

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width:80%;
  justify-content: space-evenly;
  align-items: center;
  min-height: 15rem;
  margin-top: 5rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    grid-template-columns: 1fr;
  }
`