import styled from "styled-components";
import "typeface-roboto";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const ConditionsNavigation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 30px;
  margin-top: 4rem;
  color: #5633f1;
  grid-gap: 3rem;
  padding-inline: 4rem;
`;

export const ConditionLink = styled.a`
  color: #5633f1;
`;

export const ConditionTitle = styled.h3`
  font-size: 24px;
  margin-top: 2rem;
  color: #5633f1;
`;

export const ConditionContainer = styled.div`
  padding: 4rem;
`

export const Numeration = styled.span`
  font-weight: bold;
  font-size: 16px;
`

export const ConditionText = styled.h5`
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
`;