import { motion } from "framer-motion";
import styled from "styled-components";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";
import { FlexBox } from "../Box/stlye";

export const NavbarWrapper = styled(motion.nav)`
  background-color: #424141;
  width: 100vw;
  height: 83.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 51;
  opacity: 0;

  @media (max-width: ${MOBILE_BREACKPOINT} ) {
    padding-inline: 20px;
  }
`;

export const Wrapper = styled(motion.div)`
  max-width: 1300px;
  width: 100%;

  @media (max-width: ${MOBILE_BREACKPOINT} ) {
    max-width: auto;
  }
`;

export const NavButton = styled(motion.a)`
  background-color: ${({ $color }) =>
    $color !== "secondary" ? "#5633F1" : "#FF40D2"};
  border-radius: 50px;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto';
  padding-inline: 23px;
  padding-block: 6px;
  cursor: pointer;
  text-decoration: none;
`;

export const ButtonsContainer = styled(motion.div)`
  margin-left: 59.22px;
  width: 23% !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 243px;
  margin-left: auto;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export const NavItemsContainer = styled(motion.div)`
  margin-left: 59.22px;
  width: 35%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 450px;
  min-width: 270px;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export const LanguageButton = styled(motion.div)`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 8px;
  color: #969696;
  cursor: pointer;
  font-weight: 500;
`;

export const LanguageContainer = styled(FlexBox)`
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export const MenuButton = styled.div`
  margin-left: auto;

  @media (min-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export const MobileNavigation = styled(motion.div)`
  height: 100vh;
  width: 0;
  opacity: 0;
  position: fixed;
  background-color: #424141;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  right: 0;
`;

export const MobileNavButtonContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
`

export const MobileNavLinks = styled.div`
  display: flex;
  width: 70%;
  margin-top: 4rem;
  height: 15%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const MobileLanguage = styled.div`
  margin-top: 70%;
  display: flex;
`

export const CancelImage = styled.img`
  width: 30px;
  position: absolute;
  top: 20px;
  left: 30px;
`