import React from 'react'
import {
  CardContainer,
  Image,
  CardText,
  CardHeader,
  CardDescription,
} from "./styles";

export default function ImageTextCard({color, image, text, title}) {
    return (
      <CardContainer color={color}>
        <Image src={image} />
        <CardText>
          <CardHeader>{title}</CardHeader>
          <CardDescription>{text}</CardDescription>
        </CardText>
      </CardContainer>
    );
}
