import React from "react";
import { FlexBox } from "../Box/stlye";
import { RadioContainer, RadioLabel, RadioButtonsContaner, RadioInput } from "./styles";

export default function Radio({ options, label, style, onChange }) {
  const generateName = () => {
    return label.split(" ").join("_").toLowerCase();
  };

  return (
    <RadioContainer style={style}>
      <RadioLabel>{label}</RadioLabel>
      <RadioButtonsContaner>
        {options.map((option) => (
          <FlexBox style={{ alignItems: "center" }}>
            <RadioInput
              name={generateName()}
              value={option.value}
              type="radio"
              onChange={onChange}
            />
            {option.name}
          </FlexBox>
        ))}
      </RadioButtonsContaner>
    </RadioContainer>
  );
}