import React from "react";
import ThirdVideo from "../../../../src/assets/landingVideos/thirdVideo.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";
import ThirdImage from "../../../assets/image/ThirdImage.png";

export default function SecondVideoWrapper(props) {
  
  return (
    <VideoWrapperView
      videoSource={ThirdVideo}
      videoImage={ThirdImage}
      textPosition="left"
      textShowTime={4400}
      headers={["Earn!", "Put your crypto", "to work!"]}
      subHeadings={[
        "- Start earning interest through staking",
        "- Discover investment opportunities selected",
        " by our research team",
        "- Invest in DeFi products with no extra steps",
        "- Learn about the products you invest in",
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
