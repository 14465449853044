import React, { useEffect, useState } from 'react'
import TextViewWrapper from '../../components/TextView';
import backgroundAboutUs from "../../assets/image/backgroundAboutUs.png";
import { LandingHeader } from '../Landing/FirstVideo';
import { CardsContainer, InputsContainer, SimulateButton, Text } from './styles';
import CryptoCard from '../../components/CryptoCard';
import styled from 'styled-components';
import { FlexBox } from '../../components/Box/stlye';
import TextInput from '../../components/TextInput';
import { SubTitle } from '../../components/Header';
import Radio from '../../components/Radio';
import ReCAPTCHA from "react-google-recaptcha";
import ImageTextCard from '../../components/ImageTextCard';
import AstronautPNG from "../../assets/image/astronaut-helmet.png";
import BinocularsPNG from "../../assets/image/binoculars.png"; 
import GalaxyPNG from "../../assets/image/galaxy.png"; 
import SaturnPNG from "../../assets/image/saturn.png"; 
import { useTranslation } from "react-i18next";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"


const Center = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
  }
`

const Header = styled(LandingHeader)`
  font-size: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 2.5em;
  }
`;

const Description = styled(SubTitle)`
  margin-top: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 22px;
  letter-spacing: -0.408px;
  width: 45%;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 25px;
    width: 100%;
  }

  color: #5633f1;
`;


export default function AboutUs() {
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = t("Bitennial - About Us");
    }, []);
  
    return (
      <TextViewWrapper image={backgroundAboutUs}>
        <Center>
          <Header>{t("About Us")}</Header>
          <span
            style={{
              fontWeight: 500,
              marginTop: "1rem",
              color: "#424141",
              textAlign: "center",
              maxWidth: 650,
            }}
          >
            {t("A COMPANY THAT PROVIDES THE BEST SOLUTIONS TO ENTER THE WORLD OF CRYPTOCURRENCIES")}
          </span>
        </Center>
        <Description>{t("Limitless Community")} :</Description>

        <Center>
          <Text>
            {t(`We are an entrepreneur team that understands the world’s current needs and the importance of consolidating a more inclusive and decentralized financial system that enables a greater global development.`)}
          </Text>
          <Text>
            {t(`With that purpose we created Bitennial, a company that provides solutions so that you can buy and sell crypto the fastest, simplest, and safest way, providing the most advanced tools.`)}
          </Text>
        </Center>

        <Description>
          <span style={{ fontWeight: 300 }}>{t("WHY")}</span> BITTENAL?
        </Description>

        <Center>
          <CardsContainer>
            <ImageTextCard
              image={AstronautPNG}
              text={t("In a word governed by technology, Bitennial brigns so you so fast, agile and secure access to the new digital economy.")}
              title={t("One Step Ahead")}
              color="#3b20b8"
            />
            <ImageTextCard
              image={BinocularsPNG}
              text={t("We invite you to explore the new possibilities that cryptocurrencies open for your personal finances and for your investments.")}
              title={t("Discover")}
              color="#1d048c"
            />
            <ImageTextCard
              image={GalaxyPNG}
              text={t("Bitennial provides and advanced plataform, with liquidity and depth of international level in several of the main currencies in the market.")}
              title={t("The universe expands")}
              color="#f3008a"
            />
            <ImageTextCard
              image={SaturnPNG}
              text={t("To be part of the bitennial is to be part of a community that expands day by day, facilitating the transit on the way to greater possibilities.")}
              title={t("Limitless community")}
              color="#1919c1"
            />
          </CardsContainer>
        </Center>
      </TextViewWrapper>
    );
}
