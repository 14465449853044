import React, { useState } from 'react';
import {
  NavbarWrapper,
  Wrapper,
  NavButton,
  NavItemsContainer,
  ButtonsContainer,
  LanguageButton,
  LanguageContainer,
  MenuButton,
  MobileNavigation,
  MobileNavButtonContainer,
  MobileNavLinks,
  MobileLanguage,
  CancelImage,
} from "./styles";
import BitennialLogo from '../../assets/icons/bitennialLogo.svg'
import menu from '../../assets/icons/menu.png'
import { Link } from "../Anchor/styles";
import { FlexBox } from '../Box/stlye';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import CloseIcon from "../../assets/icons/cancel.png"

const AnimatedNavButton = (props) => {
  return (
    <NavButton
      whileHover={{
        scale: 1.1,
        transition: { duration: .3 },
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
    />
  );
}

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};


export default function Navbar({ show }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function changeLanguage(value) {
    i18n.changeLanguage(value);
  }

  return (
    <>
      <NavbarWrapper
        animate={show ? { opacity: 1 } : { opacity: 0 }}
        variants={variants}
      >
        <Wrapper>
          <FlexBox>
            <img
              onClick={() => navigate("/")}
              src={BitennialLogo}
              style={{ width: "9rem", cursor: "pointer" }}
              alt="logo"
            />
            <NavItemsContainer>
              <Link to="/OTC">{t("OTC")}</Link>
              <Link to="/simulate">{t("Simulate Earnings")}</Link>
              <Link to="/about-us">{t("About Us")}</Link>
            </NavItemsContainer>
            <ButtonsContainer>
              <AnimatedNavButton href="https://www.bitennial.exchange/es/login/">
                {t("Sign in")}
              </AnimatedNavButton>
              <AnimatedNavButton
                href={"https://www.bitennial.exchange/es/register/"}
                $color="secondary"
              >
                {t("Sign up")}
              </AnimatedNavButton>
            </ButtonsContainer>
            <LanguageContainer style={{ marginLeft: "1rem" }}>
              <LanguageButton
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                style={i18n.language === "es" && { color: "white" }}
                onClick={() => changeLanguage("es")}
              >
                ESP
              </LanguageButton>
              <LanguageButton
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                style={i18n.language === "en" && { color: "white" }}
                onClick={() => changeLanguage("en")}
              >
                ENG
              </LanguageButton>
            </LanguageContainer>
            <MenuButton>
              <img
                onClick={() => setIsOpen(true)}
                src={menu}
                style={{ width: "2rem", cursor: "pointer", marginRight: '3rem' }}
                alt="logo"
              />
            </MenuButton>
          </FlexBox>
        </Wrapper>
      </NavbarWrapper>
      <AnimatePresence>
        {isOpen && (
          <MobileNavigation
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "100vw", opacity: 1 }}
            end={{ width: 0, opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 100 },
              default: { duration: 0.4 },
            }}
          >
            <CancelImage
              onClick={() => setIsOpen(false)}
              src={CloseIcon}
              alt="Close Icon"
            />
            <MobileNavButtonContainer>
              <AnimatedNavButton href="https://www.bitennial.exchange/es/login/">
                {t("Sign in")}
              </AnimatedNavButton>
              <AnimatedNavButton
                href={"https://www.bitennial.exchange/es/register/"}
                $color="secondary"
              >
                {t("Sign up")}
              </AnimatedNavButton>
            </MobileNavButtonContainer>
            <MobileNavLinks>
              <Link to="/OTC">{t("OTC")}</Link>
              <Link to="/simulate">{t("Simulate Earnings")}</Link>
              <Link to="/about-us">{t("About Us")}</Link>
            </MobileNavLinks>
            <MobileLanguage>
              <LanguageButton
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                style={i18n.language === "es" && { color: "white" }}
                onClick={() => changeLanguage("es")}
              >
                ESP
              </LanguageButton>
              <LanguageButton
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                style={i18n.language === "en" && { color: "white" }}
                onClick={() => changeLanguage("en")}
              >
                ENG
              </LanguageButton>
            </MobileLanguage>
          </MobileNavigation>
        )}
      </AnimatePresence>
    </>
  );
}
