import React from 'react'
import styled from 'styled-components';
import { CardContainer, CryptoText, CryptoLogo } from "./style";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const StableText = styled.h5`
  color: #bbbbbb;
  font-family: 'Roboto';
  font-size: 12px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-left: 15%;
  }
`;

export default function CryptoCard({
  selected,
  setSelection,
  image,
  code,
  value,
  stable,
  cryptoAPI,
}) {

    const getCryptoData = (cryptoCode) => {
      if (cryptoAPI.length) {
        return cryptoAPI.filter(
          (crypto) => crypto.symbol === cryptoCode.toLowerCase()
        )[0];
      }
    };


  return (
    <CardContainer
      selected={selected === code ? true : false}
      onClick={() => {
        setSelection(code);
      }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      <CryptoText>{code}</CryptoText>
      <CryptoText>
        ${getCryptoData(code) && getCryptoData(code).current_price}
      </CryptoText>
      <CryptoLogo src={image} />
      {stable ? <StableText>Stable Coin</StableText> : null}
    </CardContainer>
  );
}
