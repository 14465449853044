import React from "react"
import { StyledHeader, StyledSubTitle } from "./styles"

export function Header(props) {
  const properties = { ...props }
  if (properties.textPosition) { 
    delete properties.textPosition;
  }
  return <StyledHeader {...properties} />;
}

export function SubTitle(props) {
    const properties = { ...props };
    if (properties.textPosition) {
      delete properties.textPosition;
    }
  return <StyledSubTitle {...properties} />;
}
