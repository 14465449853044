import React, { useEffect, useState } from "react";
import SwapVideo from "../../../../src/assets/landingVideos/swap.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";

export default function SwapVideoWrapper(props) {
  return (
    <VideoWrapperView 
      id="swap"
      videoSource={SwapVideo}
      textPosition="left"
      textShowTime={100}
      headers={["Swap"]}
      subHeadings={[
        "- Fast and easy to use.",
        "- 24 hs availability.",
        "- Choose two cryptocurrencies, set the amount and swap away!",
        "- Set your favourite assets to make your next swap even faster"
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
