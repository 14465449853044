import styled from "styled-components"
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #e6dde0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
`

export const ViewImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 0;
  }
`

export const TransparetModal = styled.div`
  z-index: 9;
  width: 70%;
  min-height: 1250px;
  background: linear-gradient(180deg, #f8f2f2 0%, #ddd5dd 100%);
  opacity: 0.85;
  padding-block: 4rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 100%;
    min-height: 1000px;
    padding: 0;
    margin-bottom: 5%;
    justify-content: center;
  }
`
