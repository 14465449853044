import React, { useEffect, useState } from "react";
import SecondVideo from "../../../../src/assets/landingVideos/secondVideo.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";
import SecondImage from "../../../assets/image/SecondImage.png";

export default function SecondVideoWrapper(props) {
  
  return (
    <VideoWrapperView
      videoSource={SecondVideo}
      videoImage={SecondImage}
      buttonLabel={"View Demo"}
      textPosition="right"
      textShowTime={3500}
      headers={["Discover a", "simple way", "to invest"]}
      subHeadings={[
        "- Buy, Sell and Swap",
        "- Monitor your portfolio all in one place",
        "- Real time trading, round the clock",
        "- Multiple Currencies",
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
