import React from "react";
import SummerVideo from "../../../../src/assets/landingVideos/summerVideo.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";
import SummerImage from "../../../assets/image/SummerImage.png";

export default function SummerVideoWrapper(props) {
  return (
    <VideoWrapperView
      videoSource={SummerVideo}
      videoImage={SummerImage}
      textPosition="right"
      textShowTime={4400}
      headers={["Invest from", "anywhere, join our", "crypto community."]}
      subHeadings={[
        "- Discover our Mobile app!"
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
