import { motion } from "framer-motion";
import styled from "styled-components";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const CardContainer = styled(motion.div)`
  min-width: 180px;
  height: 120px;
  background-color: #5b5859;
  border-radius: 16px;
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  border: ${({selected})=> selected ? "3px solid black" : null};
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: inline-block;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    width: 150px;
    padding: 0;
  }
`;

export const CryptoLogo = styled(motion.img)`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 48px;
  height: 48px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    height: 40px;
    width: 40px;
  }
`
export const CryptoText = styled(motion.h4)`
  color: white;
  font-family: "Roboto";
  font-size: 24;
  font-weight: 500;
  margin-block: 14px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-left: 15%;
    font-size: 0.95em;
  }
`