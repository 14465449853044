import { motion } from "framer-motion"
import styled from "styled-components"
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"

export const Button = styled(motion.button)`
  background-color: ${({ $color }) =>
    $color !== "secondary" ? "#5633F1" : "#FF40D2"};
  border-radius: 50px;
  border: none;
  color: #ffffff;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 73%;
    margin-top: 10px;
  }
`

export const CTAButton = styled(motion.button)`
  background-color: ${({ disabled }) => (disabled ? "#5633f14f" : "#5633f1")};
  border-radius: 7.7px;
  color: white;
  font-size: 21px;
  padding: 10.26px;
  border: none;
  min-width: 178px;
  min-height: 50px;
  font-family: "Merriweather";
  font-weight: 700;
  cursor: pointer;
`
