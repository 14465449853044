import styled from "styled-components";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"

export const CardContainer = styled.div`
  display: flex;
  background-color: ${({ color }) =>  color};
  height: 100px;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 74%;
  }
`

export const Image = styled.img`
@media (max-width: ${MOBILE_BREACKPOINT}) {
  width: auto;
  height: 100%;
}
`;

export const CardText = styled.div`
    width: 80%;
    padding-left: 2rem;
`

export const CardHeader = styled.h3`
    color: white;
    font-weight: bold;
    font-family: 'Roboto';
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
    @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 0.9em;
  }
`

export const CardDescription = styled.h4`
  color: white;
  font-weight: 300;
  font-family: "Roboto";
  text-transform: uppercase;
  font-size: 12px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 0.6em;
  }
`;