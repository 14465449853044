import React, { useEffect, useState } from 'react'
import TextViewWrapper from '../../components/TextView';
import SummerBackground from "../../assets/image/backgroundSummer.png";
import { LandingHeader } from '../Landing/FirstVideo';
import { InputsContainer, SimulateButton } from './styles';
import CryptoCard from '../../components/CryptoCard';
import styled from 'styled-components';
import { FlexBox } from '../../components/Box/stlye';
import TextInput from '../../components/TextInput';
import { SubTitle } from '../../components/Header';
import Radio from '../../components/Radio';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";


const Center = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Header = styled(LandingHeader)`
  font-size: 3rem;
`;

const Description = styled(SubTitle)`
  margin-top: 2rem;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 30px;
  max-width: 718px;
`;

export default function Contact() {
    const [formData, setFormData] = useState({});

    const handleChangeInput = (e, input) => {
        let value = e.currentTarget.value;

        setFormData(currentState => {
        let newState = { ...currentState, [input]: value };
        return newState;
      });
    }
  
    const areInputsCompleted = () => {
      const fields = ["name", "lastname", "email", "subject", "amount"];
      let areCompleted = true;
      fields.map(inputName => {
        if (!formData[inputName]) {
          areCompleted = false;
        };
      })
      return areCompleted;
    }

    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = "Bitennial - Contact Us";
    }, []);
  
    const handleSendMessage = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };
      fetch(process.env.REACT_APP_API + "landing/api/contact/", requestOptions)
        .then((response) => response.json())
        .then((data) => setFormData({}));
    }
    const { t, i18n } = useTranslation();
    return (

      <TextViewWrapper image={SummerBackground}>
        <Center>
          <Header>{t("Contact")}</Header>
          <span style={{ fontWeight: 500, color: "#424141" }}>{t("LET'S TALK")}</span>
          <Description>
           {t("If you have any questions or want to know more about us, please contact us by completing the form below. We will be back to you shortly!")}
          </Description>
        </Center>

        <Center>
          <InputsContainer>
            <TextInput
              fullWidth
              label={t("Name")}
              type="text"
              placeHolder={t("Enter your name")}
              onChange={(e) => handleChangeInput(e, "name")}
            />
            <TextInput
              fullWidth
              label={t("Last Name")}
              type="text"
              placeHolder={t("Enter your Last Name")}
              onChange={(e) => handleChangeInput(e, "lastname")}
            />
            <TextInput
              fullWidth
              label="Email"
              type="text"
              placeHolder={t("Enter Your Email")}
              onChange={(e) => handleChangeInput(e, "email")}
            />
            <TextInput
              fullWidth
              label={t("Subject")}
              type="text"
              placeHolder={t("Enter your last subject")}
              onChange={(e) => handleChangeInput(e, "subject")}
            />
            <TextInput
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 3,
              }}
              fullWidth
              textArea
              label={t("Enter message")}
              type="text"
              placeHolder={t("Enter your message")}
              onChange={(e) => handleChangeInput(e, "amount")}
            />
          </InputsContainer>

          <Radio
            style={{ marginTop: "7rem", paddingBottom: "2rem" }}
            options={[{ name: t("Yes") }, { name: "No" }]}
            label={t("DO YOU HAVE A BITENNIAL ACCOUNT?")}
          />

          <div style={{ paddingBottom: "2rem" }}>
            <ReCAPTCHA sitekey="6LdAQuseAAAAAJyLv1DFA7o08d6yvEFVmBAsjc4H" />
          </div>

          <SimulateButton
            disabled={!areInputsCompleted()}
            onClick={handleSendMessage}
            type="squared"
          >
            {t("SEND NOW")}
          </SimulateButton>
        </Center>
      </TextViewWrapper>
    );
}
