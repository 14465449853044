import React, { useEffect, useState } from "react";
import FourthVideo from "../../../../src/assets/landingVideos/fourthVideo.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";
import { useTranslation } from "react-i18next";
import FourthImage from "../../../assets/image/FourthImage.png";

export default function FourthVideoWrapper(props) {
  const { t } = useTranslation();
  return (
    <VideoWrapperView
      videoSource={FourthVideo}
      videoImage={FourthImage}
      textPosition="left"
      textShowTime={6800}
      headers={["Safely trade", "in a friendly", "platform."]}
      subHeadings={[
        "- If you HODL, go cold. To maximize security ",
        "98% of our crypto are in cold storage",
        "- Safety protocols ",
        "(data encryption, protection against DDoS)",
        "- 2FAuthentication to further protect your account"
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
