import React, { useEffect } from "react"
import VideoWrapper from "../../../components/Video"
import FirstVideo from "../../../../src/assets/landingVideos/firstVideo.mp4"
import { Header, SubTitle } from "../../../components/Header"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import useTimer from "../../../Hooks/useTimer"
import { useTranslation } from "react-i18next";
import FirstImage from '../../../assets/image/FirstImage.png'
import deviceType, { MOBILE_BREACKPOINT } from "../../../utils/breakpoints"

export const LandingHeader = styled(Header)`
  position: relative;
  z-index: 2;
`

const AnimationContainer = styled.div`
  scroll-snap-align: start;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    scroll-snap-align:initial;
  }
`

const AnimatedContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  margin-top: 7rem;
  z-index: 10;
  flex-direction: column;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-top: 15px;
  }
`;

const LandingSubTitle = styled(SubTitle)`
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export default function FirstVideoWrapper({ onLoad, finishLoading }) {
  const device = deviceType();
  const time = device !== 'desktop' ? 100 : 5200;

  const showTitle = useTimer(time, finishLoading)
  const { t } = useTranslation();

  return (
    <AnimationContainer id="Swap">
      <AnimatePresence>
        {showTitle && (
          <AnimatedContainer animate={{ opacity: 1, y: 20 }}>
            <LandingHeader>{t("Limitless community")}</LandingHeader>
            <LandingSubTitle>
              {" "}
              {t(
                "Swap, Earn and invest crypto in the easiest way. Be part of a community that constantly excels itself"
              )}{" "}
            </LandingSubTitle>
          </AnimatedContainer>
        )}
      </AnimatePresence>

      <VideoWrapper
        style={{marginTop:'15%'}}
        videoImage={FirstImage}
        onLoad={onLoad}
        src={FirstVideo}
        autoPlay={true}
      />
    </AnimationContainer>
  );
}
