import React, { useEffect, useState } from "react";
import InformedVideo from "../../../../src/assets/landingVideos/informedVideo.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";
import InformedImage from "../../../assets/image/InformedImage.png";

export default function InformedVideoWrapper(props) {
  return (
    <VideoWrapperView
      videoSource={InformedVideo}
      videoImage={InformedImage}
      textPosition="right"
      textShowTime={5800}
      headers={["One place", "to get informed,", "learn and thrive"]}
      subHeadings={[
        "- Team of professional experts",
        "at your disposal",
        "- Specialized courses and training",
        "- Join our community, exchange ",
        "experiences and opinions"
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
