import styled from "styled-components";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const FooterContainer = styled.div`
  background-color: #5633f1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  scroll-snap-align: ${({ removeScroll }) => removeScroll ? 'none' : 'start'};
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    scroll-snap-align:initial;
  }
`;

export const Logo = styled.img`
  width: 50px;
  margin-bottom:20px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-left: 1rem;
  }
`;

export const LogoText = styled.div`
  color: white;
  font-size: 18px;
  font-family: "Merriweather";
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-top: 0.6rem;
    margin-left: 1rem;
  }
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`

export const FooterContentContainer = styled.div`
    width: auto;   
`

export const FooterNavigation = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 400px;
    column-gap: 3rem;
    width: auto;
    @media (max-width: ${MOBILE_BREACKPOINT}) {
      column-gap: 0rem;
    }
    a{
      line-height: 18px;
    }
`

export const FooterMediaContainer = styled.div`
  width: 100%;
  border-top: 1px solid white;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-right: 1rem;
  }
`;

export const CopyRightText = styled.div`
    color: white;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-left: 0.5rem;
  }
`

export const MediaIcon = styled.img`
  width: 2rem;
  cursor: pointer;
  margin-left: 10px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-right: 0.5rem;
  }
`;

export const MediaIconsContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;
