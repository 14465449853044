export const TermsAndConditionsData = [
  {
    title: "Definitions",
    conditions: [
      {
        title: "Affiliated:",
        content: `means any company related by a relationship of direct or indirect corporate control, including any entity that is fully controlled, that is controlling or that is subject to joint control. “Control” means the ownership of more than fifty percent of the shares.`,
      },
      {
        title: "Business Day:",
        content: `means any day that is not Saturday, Sunday or a day in which the commercial banks of the corresponding jurisdiction are obligated or authorized by the applicable laws to remain closed.`,
      },
      {
        title: "Legal Tender:",
        content: `is the money of legal tender; that is, that has support from the monetary authority of some country.`,
      },
      {
        title: "Virtual Currency:",
        content: `is the digital representation of value that can be the object of digital commerce and whose functions are to constitute a means of exchange, and / or a unit of account, and / or a reserve of value, but that are not legal tender, neither are issued, nor are they guaranteed by any country or jurisdiction. Examples of these are Bitcoin, Etherum, Dash and Monero, among others.`,
      },
      {
        title: "Platform:",
        content: `refers to the technical, functional and organizational structure managed by the Administrator, which allows Users to engage in and perform purchases and sales of Virtual Currencies.`,
      },
      {
        title: "Transaction:",
        content: `is the agreement accepted by a User for the purchase and sale of Virtual Currencies at the prices described and / or established through the Platform at the time the transaction is carried out.`,
      },
    ],
  },
  {
    title: "Scope",
    conditions: [
      {
        title: "",
        content: `The present T&C constitute a valid and binding contract between the User who uses the Site, on the one hand, and the Administrator, on the other. In the event that the User enters the Site and / or makes use of the Site, it will be understood that he has fully and unreservedly accepted the present T&C.`,
      },
      {
        title: "",
        content: `The present T&C constitute a valid and binding contract between the User who uses the Site, on the one hand, and the Administrator, on the other. In the event that the User enters the Site and / or makes use of the Site, it will be understood that he has fully and unreservedly accepted the present T&C.`,
      },
      {
        title: "",
        content: `Consequently, when using the Site, Users are obligated with all the scope of the law to comply with all the provisions contained in the T&C. Any relationship established between the Administrator and the Users by virtue of the use of the Site, and / or any other relationship derived and / or linked to the use of the Site shall be governed by these T&C and by the laws, statutes and regulations applicable.`,
      },
      {
        title: "",
        content: `Users accept that certain services necessary for the use of the Site and / or the Platform may be provided by Affiliated companies to the Administrator. In these cases, an independent contractual link will be established between the Users and those companies for the provision of those services. These services may be subject to differentiated rates. The User may not make any claim to the Administrator derived and / or linked to the provision of services performed by the Affiliates.`,
      },
    ],
  },
  {
    title: "Services",
    conditions: [
      {
        title: "",
        content: `The Platform hosted on the Site allows Users to carry out transactions of exchange -purchase and sale- of Virtual Currencies, using the funds in the Virtual Currencies. To operate within the Platform, Users must deposit their funds in Virtual Currencies. Users may also, when they so wish, withdraw their funds from their Account in accordance with the procedures, times and manners established in these T&C.`,
      },
      {
        title: "",
        content: `In these cases, The Administrator only manages the Platform to allow the interaction of the Users in the operations of Virtual Currencies. In no case does the Administrator act actively in the relationship and / or transactions made by the Users. In no case does the Administrator act directly in the purchase and sale of Virtual Currencies.`,
      },
      {
        title: "",
        content: `The Platform hosted on the Site allows Users to including the possibility of entering into Loan Contracts, the User must have accepted these T&C and deliver their contributions only in Cryptocurrencies.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `The receipt of Cryptocurrencies transferred by the User to the Company is only and exclusively accredited only at the time the Company delivers the corresponding receipt to the User with an indication of the amount, shipping and receiving addresses, GMT date and time of the transfer.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `Upon acknowledging receipt of the Cryptocurrencies transferred by the User, the Company will credit them in the User’s individual account on the Digital Platform and will be available to conclude Loan Contracts.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `When, for whatever reason, the User’s account belongs to several people, they must designate a single representative.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `All payments, payment orders, instructions, requests, contracts, as well as other documents confirmed or sent by the User on the Platform will be binding on the User and the Company.`,
      },
      {
        title: "",
        hideNumeration: true,
        content:
          "The Platform allows the following cryptocurrencies to be used for Loan Contracts through the Digital Platform: Bitcoin (BTC), Dollar Tether (USDT).",
      },
      {
        title: "",
        hideNumeration: true,
        content: `The User, at its discretion, will choose one or more Loan Contracts offered on the Digital Platform and should carefully review the Loan Agreement offered, which includes: Loan cryptocurrency, Loan Amount / Number of Contracts, Loan interest rate, Loan term, Loan repayment date, Nominal value of each contract: USDT 1 or equivalent in Bitcoin units (BTC).`,
      },
    ],
  },
  {
    title: "Deposits and Withdrawals of Funds",
    conditions: [
      {
        title: "",
        content:
          "Deposits. All contributions must consist of eligible cryptocurrencies and must be transferred to the deposit address provided in your Bitennial account or the address that is notified to you.",
      },
      {
        title: "",
        content: `We have the right to refuse any deposit and the right to return an already made deposit.`,
        hideNumeration: true,
      },
      {
        title: "",
        hideNumeration: true,
        content: `The transfer of said eligible cryptocurrency to your Interest Account will not be considered liquidated and completed until the transaction has been recorded in one block and five (5) subsequent consecutive blocks that refer to the same block (i.e., six (6) blocks) in total) have been added to the applicable blockchain.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `Any deposit received on any business day at 11:59 p.m. or before UTC-0 will be treated by us as received that business day.`,
      },
      {
        title: "",
        content: `Withdrawals. Withdrawals are processed weekly on Fridays between 12:00 and 23: 59h UTC-0.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `The User will request the withdrawal until that day inclusive, until 11:59 am (UTC-0).`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `The User declares to understand that in case of processing the request after the established time, the withdrawal may take up to 7 days.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `In the case of a withdrawal, the user will be asked to provide the details of the account to which they wish to transfer their cryptocurrency. In the event that the details you provide are inaccurate, incomplete or misleading, your cryptocurrency may be permanently lost. When you withdraw all or any of your cryptocurrencies, we will not be liable for any loss resulting from inaccurate, incomplete or misleading details that you may provide for such transfer. If the account you specify is one to which we cannot process transfers, we will have no responsibility for any failure or delay in the processing of your requested withdrawal.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `Any blockchain transaction fee charged by third parties in connection with your withdrawal will be deducted from the amount of the cryptocurrency you withdraw from your Account. For example, if you withdraw 1 Bitcoin from your account and the Bitcoin network charges a transaction fee equal to .01 Bitcoin, you will receive .99 Bitcoins.`,
      },
      {
        title: "",
        content: `Interest calculation. By signing a p2p Loan Agreement, the User will begin to receive the interest at their agreed rate in their individual account, from the following day, taking UTC-0 as a reference.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `Interest is credited to the User’s account on a daily basis at the close of each day on an annual basis of 365 days in all cases.`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `All interest will be paid in cryptocurrencies. Accrued interest will be calculated and paid to the hundredth millionth of a Bitcoin (0.00000001). Stablecoin interest will be calculated to one hundredth of a unit (0.01). Any digit beyond that will be truncated; Balances and interest payments on account statements will be truncated to four digits (0.0001).`,
      },
      {
        title: "",
        content: `Errors. If, for whatever reason, the User’s Cryptocurrency account was credited or debited in error, The Administrator has full powers to make the subsequent debits or accreditations in the User’s account to adequately remedy the situation.`,
      },
    ],
  },
  {
    title: "Registation",
    conditions: [
      {
        title: "",
        content: `To make use of the Site and the Platform, the User must proceed to register by completing the registration form within the Site (the ‘Form’), which shall have the character of an affidavit. The user must complete the Form with reliable, real, complete and updated information; If necessary, the User must update the Form from time to time. The Administrator reserves the right to request information and / or additional documentation in order to corroborate the information contained in the Form. Until the information required for the use of the Platform is complete, the Administrator may limit the use of the Platform by the User. The Administrator also reserves the right to temporarily suspend and / or cancel the account of Users that provide false information or that could not be corroborated by the Administrator. The User shall not have the right to make any claim to the Administrator by virtue of the suspension or cancellation of his account in accordance with the provisions of this clause.`,
      },
      {
        title: "",
        content: `The User must access the Platform through the personal account created for that purpose at the time of registration (the ‘Account’), by entering their personal email address and a password (alphanumeric) that will be created to those effects. The Account is strictly personal, unique and non-transferable; it will be assigned to a single User for its exclusive use and its sale and / or transmission to third parties will be strictly prohibited. Users may only have a single account at any given time, and may not create and / or use other accounts that are not their own Account. The User will be responsible for keeping the data of his Account confidential and especially his password. In the event that the Administrator detects that the Account is used by a User who is not the owner of the Account, or that a User is operating with more than one Account, he / she may suspend or cancel the User’s Account(s). In all cases, the User will be responsible for the operations carried out from his Account. The User must immediately notify the Administrator regarding any unauthorized use of his Account, for the purpose of the Administrator taking the appropriate measures for such purposes, including the temporary suspension of the Account.`,
      },
    ],
  },
  {
    title: "User Statements",
    conditions: [
      {
        title: "",
        content: `By creating his Account, the User declares and guarantees:`,
        hideNumeration: true,
      },
      {
        title: "6.(i)",
        content: `To have read and accepted the present T&C.`,
        hideNumeration: true,
      },
      {
        title: "6.(ii)",
        content: `To have sufficient legal capacity to use the Site and the Platform, as well as to carry out transactions through the Platform. In this sense, Users declare that they are at least 18 (eighteen) years of age, or have the legal capacity to contract according to the laws of their country of residence or habitual residence.`,
        hideNumeration: true,
      },
      {
        title: "6.(iii)",
        content: `To be providing real, complete and updated information when completing the Form and using the Platform as required.`,
        hideNumeration: true,
      },
      {
        title: "6.(iv)",
        content: `To know and accept expressly the fluctuations of the Virtual Currencies. In this sense, the User declares and guarantees to be aware of the nature, legal situation and volatility of any Virtual Currency that is operated through the Platform, and fully and completely assumes the risk of carrying out Transactions with such assets, giving up the right to file claims to the Administrator under such Transactions.`,
        hideNumeration: true,
      },
      {
        title: "6.(iii)",
        content: `To recognize that the information related to Transactions previously made in the Platform should not be considered as educational material.`,
        hideNumeration: true,
      },
      {
        title: "6.(iii)",
        content: `To be responsible for determining if a specific Transaction is appropriate based on its own personal goals, financial status and willingness to take risks.`,
        hideNumeration: true,
      },
    ],
  },
  {
    title: "Obligations of Users",
    conditions: [
      {
        title: "",
        content: `Users will only use the Platform to carry out Transactions in accordance with the provisions of these T&C.`,
      },
      {
        title: "",
        content: `Users will not use the Platform to carry out illicit activities, including, but not limited to, money laundering and terrorist financing.`,
      },
      {
        title: "",
        content:
          "Users will not use the Platform in such a way that it reduces the performance and capacity of it.",
      },
      {
        title: "",
        content: `Each User will be responsible for all the obligations and tax charges that correspond to their operations on the Site (including, if applicable, the payment of income tax and the payment of value added tax), without being able to charge on the Administrator or its Affiliates any type of responsibility derived from the non compliance of the Users with respect to their tributary obligations. In any case, the Administrator is not responsible for the effective compliance with tax or tax obligations established by current law. In the event that the Administrator and / or its Affiliates are obliged by law in force in any jurisdiction to act as an agent of collection or withholding or under any other fiscal figure, they may make all withholdings and / or charges necessary to the User to effects of fulfilling their tax obligations.`,
      },
    ],
  },
  {
    title: "Functionalities and Processes of the Platform",
    conditions: [
      {
        title: "",
        content: `The Site allows Users to buy and sell Virtual Currencies through the Platform. The price by which Users can buy and sell Virtual Currencies will vary depending on the market price and the decisions of each User. Neither the Administrator nor the Platform imposes a price on Users for the purchase and / or sale of the Virtual Currencies.`,
      },
      {
        title: "",
        content: `Users understand that the Administrator does not buy or sell Virtual Currencies directly, nor actively participate in the purchase and sale of Virtual Currencies through the Platform. The Platform only enables the access of Users to the market for the purchase and sale of Virtual Currencies.`,
      },
      {
        title: "",
        content: `Users may establish buy and sell orders for Virtual Currencies on the Platform at a specific price. These orders will be considered as public offers for the purchase and sale of Virtual Currencies and may be revoked as long as they have not been accepted by a counterparty. The Platform will allow, through automated processes, that buy and sell orders be paired for the purpose of producing Transactions of purchase and sales of Virtual Currencies. As long as the purchase and sale orders are paired, they will be considered accepted and may not be revoked by the Users.`,
      },
      {
        title: "",
        content: `The Platform hosted on the Site allows Users to including the possibility of entering into Loan Contracts, the User must have accepted these T&C and deliver their contributions only in Cryptocurrencies.`,
      },
    ],
  },
  {
    title: "Risk",
    conditions: [
      {
        title: "",
        content: `Given the nature of their commercialization, and the fluctuating nature of the price of Virtual Currencies in the market, the Administrator will not be responsible for the loss of money incurred by Users as a result of the increase or decrease in the value of the Virtual Currencies.`,
      },
      {
        title: "",
        content: `Unlike Legal Tender, Virtual Currencies are backed by underlying protocols and technologies, and in the trust generated in the general public. Users expressly acknowledge that Virtual Currencies have not been recognized as coins or means of payment by various monetary authorities, and that the legal treatment of Virtual Currencies differs in different jurisdictions. By virtue of the foregoing, Users assume and are fully responsible for the risk of operating with Virtual Currencies, releasing from any responsibility or consequence emanating from it the Administrator and its Affiliated companies, its operators, shareholders, partners, administrators, managers, workers and owners.`,
      },
      {
        title: "",
        content: `The Administrator has no authority or control over the software protocols that govern the operations in the Virtual Currencies that operate on the Platform. In general, these protocols are open source and anyone can use them, copy them, modify them and distribute them. The User acknowledges and accepts that (i) the Administrator is not responsible for the operation of the underlying protocols and does not guarantee their functionality, security or availability and (ii) the protocols are subject to sudden changes in their operating rules (this is the case the possible bifurcations or ‘forks’). Such bifurcations may materially affect the value, functionality and / or denomination of the Virtual Currencies stored in the Platform.`,
      },
      {
        title: "",
        content: `The User declares to know, understand and accept the legal, tax and accounting characteristics and consequences, as well as the risks involved in the operations on the Digital Platform that may involve losses of the value of its assets and that the Company does not guarantee any results to the User , and especially the following:`,
      },
      {
        title: "Market risk:",
        hideNumeration: true,
        content: `due to variations and high volatility in the price of Cryptocurrencies that may involve losses of the value of the assets.`,
      },
      {
        title: "Counterparty risk:",
        hideNumeration: true,
        content: `for the relationship that exists with Policyholders, Third Parties and the Company and their willingness to fulfill their obligations.`,
      },
      {
        title: "Liquidity risk:",
        hideNumeration: true,
        content: `due to the impossibility of having the necessary liquidity for the opening or closing of operations at a certain moment.`,
      },
      {
        title: "Regulatory risk:",
        hideNumeration: true,
        content: `due to uncertainty about changes in current regulations.`,
      },
      {
        title: "Political risk:",
        hideNumeration: true,
        content: `due to the political situation of a country in terms of public order, legal stability and security.`,
      },
      {
        title: "Operational risk:",
        hideNumeration: true,
        content: `due to the possibility of incurring losses due to deficiencies, failures or inadequacies in human and / or technological resources.`,
      },
      {
        title: "Risk of money laundering and terrorist financing:",
        hideNumeration: true,
        content: `due to the possibility of loss or damage that may be suffered or in which an entity directly or indirectly involved with events likely to be classified as Money Laundering and / or Terrorism Financing.`,
      },
    ],
  },
  {
    title: "Responsabilty",
    conditions: [
      {
        title: "",
        content: `The User acknowledges and accepts that he uses the Site under his sole and exclusive responsibility. Each User will be responsible for his actions within the Site. The Users know and accept that when carrying out operations through the Site, they do so at their own risk. In no case shall the Administrator be held accountable for any damages that the User may suffer, including loss of profits, loss of business, loss of opportunities, loss of data, and any other direct and / or indirect loss or damage.`,
      },
      {
        title: "",
        content: `The Administrator is not responsible for any damage, loss or damage suffered by a User caused by failures in the Platform, on the server or on the Internet, and will not be responsible for any virus that could infect the User’s equipment as a result of access, use or examination of the Site or following any transfer of data, files, images, texts, or audio contained therein. The Users can not impute any responsibility or demand compensation, by virtue of damages resulting from technical difficulties or failures in the systems or on the Internet. The Administrator does not guarantee access and continued or uninterrupted use of the Site. The system may eventually not be available due to technical difficulties or failures of the network, or for any other circumstance outside the Administrator; in such cases, efforts will be made to restore it as quickly as possible, without thereby imposing any responsibility on the Administrator.`,
      },
      {
        title: "",
        content: `The Administrator is not responsible for any damages arising from causes not attributable to it and that may affect the Platform or the User. Causes not attributable to the Administrator are those caused by force majeure or fortuitous event, such as natural disasters or cyber attacks, as any other circumstance beyond the reasonable control of the Administrator.`,
      },
      {
        title: "",
        content: `In cases where the Site contains links to other sites and / or resources provided by third parties, these links are provided solely for the User’s information. The Administrator has no control over the contents of those sites and resources, and assumes no responsibility for them or for losses and damages that may occur when using them.`,
      },
      {
        title: "",
        content: `The Users express and guarantee that they are the legitimate owners of their Accounts and that they are allowed to use all the sums of Virtual Currencies deposited in their Account and that the transactions carried out in the Platform do not infringe the rights of third parties or the applicable laws. In case of fraud, the Administrator will report all information necessary for the investigation of fraud or other allegedly illegal activity.`,
      },
      {
        title: "",
        content: `Nothing in these T&C excludes or limits Users’ liability for fraud, death, or damage to persons caused by their negligence, violation of the terms of service implied by current regulations, or any other liability that is not limited or excluded by the applicable laws.`,
      },
    ],
  },
  {
    title: "Rates and Commissions",
    conditions: [
      {
        title: "",
        content: `The Administrator will not charge Users any amount for registering on the Site.`,
      },
      {
        title: "",
        content: `The Administrator shall charge Users a commission for each Transaction made through the Platform that shall be expressed as a percentage of the amount of the purchase or sale transaction, as applicable. The commission will be expressed in the amount of US dollars that corresponds according to the operation in question. The commissions will be deducted from the User’s Account at the time the order is executed. The Administrator will charge a commission between 0.5% and 0.65% of the amount traded, for each purchase transaction and for each sale made by the User through the Platform.`,
      },
      {
        title: "",
        content: `The scale of charges applicable to the User for Loan Contracts is detailed in the following table:`,
      },
      {
        title: "",
        content: `Charges for signing a Contract: 0%.`,
        hideNumeration: true,
      },
      {
        title: "",
        hideNumeration: true,
        content: `Charges for termination of a Contract: 0%`,
      },
      {
        title: "",
        hideNumeration: true,
        content: `This table may be modified prior notification to the User through the Digital Platform of the Fund five (5) days prior to its entry into force.`,
      },
    ],
  },
  {
    title: "Confidentiality and Treatment of User Information",
    conditions: [
      {
        title: "",
        content: `he User accepts that the Administrator makes use of the information and personal data provided through the Form and / or otherwise through the Platform for the provision of the service, as well as for other related activities respecting at all times the confidentiality of the information supplied. In particular, the Administrator may use anonymized information of the Users with respect to the transactions carried out in the Platform in order to provide market information, such as a record and transaction history, record of open orders and generation of graphics. The User accepts and understands that, in certain cases (judicial request, legal mandate, etc.), the Administrator may transfer the information provided by the User to public entities with powers to request such information, and even to private entities to the extent that this is necessary and justifiable for the execution of the present T&C.`,
      },
      {
        title: "",
        content: ` The Administrator commits to: (i) maintain in absolute confidentiality the personal information provided by the Users at the time of registration, unless express consent of the User indicating otherwise; (ii) to use the personal information of the Users for the purposes established in these T&C, unless express consent of the User indicating otherwise; (iii) to make appropriate, relevant and non-excessive use of the personal information of the Users; (iv) to keep the personal information of the Users accurate and, as far as possible, updated; and, (v) to adopt the technical, organizational and legal measures necessary to guarantee the security and confidentiality of the personal information provided by the Users.`,
      },
      {
        title: "",
        content: `The scale of charges applicable to the User for Loan Contracts is detailed in the following table:`,
      },
      {
        title: "",
        content: `The User expressly authorizes the Administrator and / or its Affiliates to share the personal information provided at the time of registration and verification of their Account, with financial institutions in case they are required by money laundering prevention procedures, terrorism financing and / or prevention of fraud and with service providers or affiliated companies that contribute to service and / or improve or facilitate operations through the Platform in all jurisdictions where the service is available, such as means of payment, means of collection of payment, banking entities, insurance or intermediaries in the management of payments and call centers, among others. In such cases the Administrator will ensure that certain standards are met in the transmission of information, by signing agreements the purpose of which is the privacy and confidentiality of the personal data of the Users.`,
      },
    ],
  },
  {
    title: "Declaration of legal funds",
    conditions: [
      {
        title: "",
        content: `The User expressly declares that the origin of the funds used for any Transaction made through the Platform comes from lawful activities, assuming to the Administrator and / or its Affiliates civil liability arising from the breach of this contractual obligation; without prejudice to the administrative and / or criminal liability that the User may assume against the competent authorities.`,
      },
      {
        title: "",
        content: `Notwithstanding the foregoing, the Administrator undertakes to comply with the applicable rules on prevention of money laundering and terrorist financing of the different jurisdictions where it operates, in case it is required to comply with those regulations, which means that will make the necessary verifications of the information provided by the Users, as well as that information not provided but that is within the reach of the Administrator to take preventive measures and / or immediately notify the competent authorities before the suspicion of carrying out illicit activities by the the users.`,
      },
    ],
  },
  {
    title: "Intellectual property",
    conditions: [
      {
        title: "",
        hideNumeration: true,
        content: `All contents of the Site, including, but not limited to, text, names, data, logos, brands, icons, code, methods, techniques, models, graphics and the software used, are the property of the Administrator and / or its Affiliates and are protected for patents, copyrights and trademarks, and other applicable legal institutes.`,
      },
    ],
  },
  {
    title: "Branches",
    conditions: [
      {
        title: "",
        content: `In the event that a User fails to comply with any of the provisions established in these T&C the Administrator may take the following measures, depending on the severity of the breach: (i) send a warning to the User, (ii) suspend the User’s account by a certain time, (iii) cancel the User’s account definitively and (iv) act against the User in accordance with the applicable laws, being able to claim the User for the damages and losses caused.`,
      },
      {
        title: "",
        content: `In case of definitive closure of a User’s Account, the Administrator will send an email to the User requesting the necessary information with the purpose of transferring to the user the funds available in the Account, among which will be included, as appropriate, the data of a virtual wallet and the data of a bank account. The Administrator will make a reasonable effort to return the funds within 15 (fifteen) days of receiving the necessary information from the User.`,
      },
    ],
  },
  {
    title: "Termination",
    conditions: [
      {
        title: "",
        content: `These T&C may be rescinded without expression of cause by the Administrator. In the event that the Administrator decides to terminate the T&C without cause, that rescission will be effective within 30 (thirty) days after the decision is informed to the Users through the Site. In the event of termination of the T&C, the provisions of clause 13.2 shall apply with respect to the return of the funds to the Users, with the Administrator in this case making a reasonable effort to return the funds within 60 (sixty) days.`,
      },
      {
        title: "",
        content: `The T&C may also be rescinded without expression of cause by the Users. In such case the Users must request the cancellation of their Account to the Administrator, who will proceed to cancel it within the term of 30 (thirty) days counted since the request for cancellation has been made. In the event of termination of the T&C, the provisions of clause 13.2 shall apply with respect to the return of the funds to the Users.`,
      },
    ],
  },
  {
    title: "Modifications",
    conditions: [
      {
        title: "",
        content: `The Administrator shall have the right to review and modify the T&C whenever he considers it necessary. In case of modifications to the T&C the Administrator will notify the Users of such modifications through an email. In the event that the Users do not express themselves against the modifications, their silence will be taken as a tacit manifestation with respect to their acceptance. Likewise, the use of the Site by Users after being notified of the modifications shall be construed as a tacit manifestation with respect to their acceptance.`,
      },
      {
        title: "",
        content: `In case the User does not accept the modifications introduced to the T&C they must proceed to close their Accounts on the Site.`,
      },
    ],
  },
  {
    title: "Uses of cookies",
    conditions: [
      {
        title: "",
        hideNumeration: true,
        content: `The Users know and accept that the Administrator may use on the Site systems and / or tracking services and / or similar technologies or that fulfill a similar function, such as Cookies. Cookies are small files that are installed on the storage site of the computer or device used by the User, with a limited duration in time to help personalize the services. Cookies are used in order to know the interests, behavior and demographics of those who visit or use the website and, in this way, better understand their needs and interests and provide a better service or provide related information. The Administrator may also use the information obtained through Cookies to analyze the pages browsed by the User, as well as their searches, to improve and / or customize commercial and promotional initiatives, make news, advertisements or promotions, banners of interest and refine your content offer; you may also use Cookies to account for and corroborate the records and levels of validation, as well as to promote and enforce the rules and security of the Site. It is established that the installation, permanence and existence of the cookies in the User’s computer or device depends on their exclusive will and can be eliminated from their computer or device when they wish.`,
      },
    ],
  },
  {
    title: "Miscellaneous",
    conditions: [
      {
        title: "",
        content: `In case any of the provisions of these T&C becomes invalid or unenforceable for legal reasons, the validity of the remaining provisions will not be affected.`,
      },
      {
        title: "",
        content: `Communications between the Administrator and Users will be made at all times through email.`,
      },
      {
        title: "",
        content: `In case of doubt about the use of the Site or the Platform, the User may contact the Administrator via email or through the Site’s support module. The Administrator will respond to inquiries made within a reasonable time.`,
      },
      {
        title: "",
        content: `The T&C will be governed by the laws of the República Argentina, excluding its rules on conflict of laws. Any dispute that may arise between the Administrator and the Users as a consequence of the use of the Site and / or the Platform, or with respect to or linked to these T&C, its validity, interpretation, application, existence, scope or compliance will be submitted to the jurisdiction of the courts of Capital Federal of the República Argentina that correspond according to the matter, renouncing any other forum or jurisdiction.`,
      },
    ],
  },
];
