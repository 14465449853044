import { motion } from "framer-motion"
import styled from "styled-components"
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"

export const StyledHeader = styled(motion.h1)`
  color: #5633f1;
  text-align: center;
  font-size: 64px;
  font-family: "initial !important";
  margin-bottom: 0;
  font-weight: bold;
  font-family: "Merriweather";
  line-height: 60px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 2.3em;
    margin-top: 15%;
    margin-bottom: 5%;
    line-height: 45px;
  }
`

export const StyledSubTitle = styled(motion.h2)`
  color: #5633f1;
  text-align: center;
  font-size: 24px;
  font-family: "Merriweather";
  font-weight: 100;
  margin-bottom: 0;
  line-height: 35px;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
`
