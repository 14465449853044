import { useState, useEffect } from "react";

export default function useTimer(time, finishLoading, handleFinish = () => {}) {
  const [isFinished, setFinished] = useState(false);

    useEffect(() => {
        if (finishLoading) {
            setTimeout(function () {
            setFinished(true);
            handleFinish(true);
            }, time);
        }
    return () => {
      setFinished(false);
    };
  }, [finishLoading]);

  return isFinished;
}