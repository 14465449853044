import React, { useEffect, useState } from "react"
import TextViewWrapper from "../../components/TextView"
import BackgroundFAQ from "../../assets/image/backgroundFAQ.png"
import { LandingHeader } from "../Landing/FirstVideo"
import styled from "styled-components"
import { FlexBox } from "../../components/Box/stlye"
import { SubTitle } from "../../components/Header"
import Button from "../../components/Button"
import {
  AcordionContainer,
  FAQsAnswer,
  FAQsContainer,
  FAQText,
  QuestionTitle,
} from "./styles"
import { AnimatePresence, motion } from "framer-motion"
import ArrowIcon from "../../assets/icons/arrow.svg"
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"
import { useTranslation } from "react-i18next";

const Center = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Header = styled(LandingHeader)`
  font-size: 3rem;
`

const Description = styled(SubTitle)`
  margin-top: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 22px;
  letter-spacing: -0.408px;
  width: 45%;
  color: #5633f1;
`

const FAQButton = styled(Button)`
  padding: 0.7em 1em;
  background-color: ${({ selected }) => (selected ? "#222222" : "#f5f5fa")};
  color: ${({ selected }) => (selected ? "#f5f5fa" : "#222222")};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    padding: 1.3em 1em;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  font-size: 18px;
  margin-top: 45px;
  max-width: 634px;
  width: 70%;
  justify-content: space-between;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const ArrowAnimation = styled(motion.img)`
  margin-left: auto;
  width: 12px;
`

const TOPICS = [
  {
    label: "First steps",
    questions: [
      {
        title: "How do I create an account in Bitennial?",
        answer: `In order to provide additional security after registration you will need to validate your email account and confirm your identity. To start operating you will be asked to upload additional information to validate your identity and to increase the operating limit established by default. We will never ask for your password or any security code created only for your eyes. Some examples of documentation that Bitennial needs to validate your account are personal identification, income statement, bank statement.`,
      },
      {
        title: "How do I validate my account?",
        answer: `In order to provide additional security after registration you will need to validate your email account and confirm your identity. To start operating you will be asked to upload additional information to validate your identity and to increase the operating limit established by default. We will never ask for your password or any security code created only for your eyes. Some examples of documentation that Bitennial needs to validate your account are personal identification, income statement, bank statement.`,
      },
      {
        title: "What is the account monthly limit?",
        answer: `To be compliant with all regulatory norms, Bitennial establishes different limit categories that you can access by validating your account and presenting documentation related to source of income.`,
      },
    ],
  },
  {
    label: "Earnings",
    questions: [
      {
        title: "How can I deposit or withdraw cryptocurrency to my account?",
        answer: `To deposit crypto to your account you should click on “deposit”, choose what asset you are going to receive and then select the corresponding network to the asset you are receiving. It is critical to choose the correct network according to the asset you are transferring as not all tokens are compatible with other blockchains. Sending a cryptocurrency to an incompatible network might result in the loss of funds. Below you will find the networks we currently operate with: Ethereum - - - - - Tokens under ERC-20 standard (DAI, USDC, USDT, ETH) Bitcoin - - - - - Only BTC Widthdraw To widthdraw crypto to another wallet you should click on “widthdraw”, choose what asset you are going to send, and then select the corresponding network to the asset you are transfering. Remember it is critical to choose the correct network according to the asset you are transferring as not all tokens are compatible with other blockchains. Sending a cryptocurrency to an incompatible network might result in the loss of funds.`,
      },
      {
        title: "Are there any limits for daily operations?",
        answer: `To be compliant with all regulatory norms, Bitennial establishes different limit categories that you can access by validating your account and presenting documentation related to source of income.`,
      },
      {
        title: "Can I transfer cryptocurrency from my account?",
        answer: `Yes, your funds are your own and you can transfer them at any moment.`,
      },
    ],
  },
  {
    label: "Swap",
    questions: [
      {
        title: "Can I register more than one account?",
        answer: `Yes, you can exchange any cryptocurrency with each other.`,
      },
      {
        title: "How do I buy/sell Bitcoin and other cryptocurrencies in Bitennial?",
        answer: `In the home screen, select swap and choose both cryptocurrencies you want to swap. Select the amount and confirm the transaction.`,
      },
      {
        title: "Is there any fee involved in using the platform?",
        answer: `Bitennial charges 0,40% of the transacted value.`,
      },
    ],
  },
]

export default function FAQs() {
  const [selected, setSelected] = useState(TOPICS[0].label)
  const [selectedAnswer, setAnswer] = useState(null)
  const { t, i18n } = useTranslation();

  const selectedFAQ = TOPICS.filter((topic) => topic.label === selected)

  const handleChangeAnswer = (answer) => {
    if (selectedAnswer === answer) {
      setAnswer(null)
    } else {
      setAnswer(answer)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Bitennial - FAQ's"
  }, [])
  return (
    
    <TextViewWrapper image={BackgroundFAQ}>
      <Center>
        <Header>{t("FAQ'S")}</Header>
        <FAQText>{t("Do you have any questions? We have answers for you")}</FAQText>

        <ButtonsContainer>
          {TOPICS.map((faq) => (
            <FAQButton
              selected={selected === faq.label}
              onClick={() => setSelected(faq.label)}
            >
              {t(faq.label)}
            </FAQButton>
          ))}
        </ButtonsContainer>

        <FAQsContainer>
          {selectedFAQ.map((faq) => (
            <>
              {faq.questions.map((question) => (
                <AcordionContainer
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleChangeAnswer(question.title)}
                >
                  <QuestionTitle>
                    {t(question.title)}{" "}
                    <ArrowAnimation
                      animate={
                        selectedAnswer === question.title
                          ? { rotate: "-90deg" }
                          : { rotate: "90deg" }
                      }
                      alt="Accordion Arrow"
                      src={ArrowIcon}
                    />
                  </QuestionTitle>
                  <AnimatePresence>
                    {selectedAnswer === question.title && (
                      <FAQsAnswer
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 0, opacity: 0 }}
                      >
                        {t(question.answer)}
                      </FAQsAnswer>
                    )}
                  </AnimatePresence>
                </AcordionContainer>
              ))}
            </>
          ))}
        </FAQsContainer>
      </Center>
    </TextViewWrapper>
  )
}
