import LandingView from "./Views/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimulateEarnings from "./Views/SimulateEarnings";
import Contact from "./Views/Contact";
import AboutUs from "./Views/AboutUs";
import OTC from "./Views/OTC";
import FAQs from "./Views/FAQs";
import TermsAndConditions from "./Views/TermsAndConditions";
import PyP from "./Views/PyP";

function App() {
  document.title = "Bitennial";

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingView />} />
      </Routes>
      <Routes>
        <Route exact path="simulate" element={<SimulateEarnings />} />
      </Routes>
      <Routes>
        <Route exact path="contact" element={<Contact />} />
      </Routes>
      <Routes>
        <Route exact path="about-us" element={<AboutUs />} />
      </Routes>
      <Routes>
        <Route exact path="otc" element={<OTC />} />
      </Routes>
      <Routes>
        <Route exact path="faqs" element={<FAQs />} />
      </Routes>
      <Routes>
        <Route
          exact
          path="terms-and-conditions"
          element={<TermsAndConditions />}
        />
      </Routes>
      <Routes>
        <Route exact path="pyp" element={<PyP />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
