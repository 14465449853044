import React, { useEffect, useState } from 'react'
import TextViewWrapper from '../../components/TextView';
import BackgroundOTC from "../../assets/image/backgroundOTC.png";
import { LandingHeader } from '../Landing/FirstVideo';
import { CardGridContainer, CardsContainer, OTCSubTitleText, SimulateButton, Text } from './styles';
import CryptoCard from '../../components/CryptoCard';
import styled from 'styled-components';
import { FlexBox } from '../../components/Box/stlye';
import TextInput from '../../components/TextInput';
import { SubTitle } from '../../components/Header';
import Radio from '../../components/Radio';
import ReCAPTCHA from "react-google-recaptcha";
import OTCCard from '../../components/OTCCard';
import { InputsContainer } from '../Contact/styles';
import { useTranslation } from "react-i18next";
import {MOBILE_BREACKPOINT} from "../../utils/breakpoints"

const Center = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Header = styled(LandingHeader)`
  font-size: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 1.75rem;
  }
`;

const Description = styled(SubTitle)`
  margin-top: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 22px;
  letter-spacing: -0.408px;
  width: 55%;
  color: #5633f1;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 22px;
    width: 91%;
    
  }
`;

const TradingDesk = styled(Description)`
  @media (min-width: ${MOBILE_BREACKPOINT}) {
    width: 100%;
    text-align: left;
    margin-left: 6rem;
    margin-top: 6rem;
  }
`;

const DescriptionInputTitle = styled(Description)`
  width: 100%;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 19px;
    font-weight: 300;
  }
`;

const DescriptionInputText = styled.div`
  font-weight: 500;
  margin-top: "1rem";
  color: "#424141";
  text-align: "center";
  font-weight: "bold";
  max-width: 650;
  margin-left: "3.5rem";
  margin-top: 1rem;
  text-align: center;

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 97%;
    text-align: center;
  }
`;


export default function OTC() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({});

    const handleChangeInput = (e, input) => {
      let value = e.currentTarget.value;

      setFormData((currentState) => {
        let newState = { ...currentState, [input]: value };
        return newState;
      });
    };

    const areInputsCompleted = () => {
      const fields = [
        "name",
        "lastname",
        "email",
        "skype_id",
        "amount",
        "crypto",
        "bitennial_account",
      ];
      let areCompleted = true;
      fields.map((inputName) => {
        if (!formData[inputName]) {
          areCompleted = false;
        }
      });
      return areCompleted;
    };
  
  
    const handleSendMessage = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };
      fetch(process.env.REACT_APP_API + "landing/api/otc/", requestOptions)
        .then((response) => response.json())
        .then((data) => setFormData({}));
    }

    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = "Bitennial - OTC";
    }, []);
  
    return (
      <TextViewWrapper image={BackgroundOTC}>
        <Center>
          <Header>{t("Bitennial OTC Trading Desk")}</Header>
          <OTCSubTitleText>
            {t(
              `THE BEST WAY TO BUY AND SELL LARGE VOLUMES OF CRYPTO. GET TO KNOW IT!`
            )}
          </OTCSubTitleText>
        </Center>
        <TradingDesk>
          {t("Bitennial OTC Trading Desk:")}
        </TradingDesk>
        <div style={{ display: "grid" }}>
          <Text>
            {t("TRADE")} <b>{t("LARGE VOLUMES")}</b>{" "}
            {t("WITH A CUSTOMIZED SERVICE.")}
          </Text>

          <Text>
            <b>{t("What is Bitennial’s OTC Trading Desk?")}</b>
          </Text>

          <Text>
            <b>{t("OTC (Over-The-Counter) trading")}</b>{" "}
            {t("happens outside Bitennial’s. ")}{" "}
            {t(
              "open exchange. We offer better market depth and a customized service"
            )}
            {t(
              " for individuals and corporations that wish to carry out trades that"
            )}
            {t(
              "could significantly affect the order book if performed on the open market."
            )}
          </Text>

          <Text>
            {t(`By using this tool you will access a customized execution and liquidation service, safe and highly competitive.`)}
          </Text>
        </div>
        <Center>
          <CardGridContainer>
            <OTCCard color="#9DA3E1" title={t("Reduced")} subtitle={t("Fees")}>
              {t("Fixed fees per trade, no fees for crypto withdrawals.")}
            </OTCCard>
            <OTCCard
              color="#FF40D2"
              title={t("TAILOR-MADE")}
              subtitle={t("SERVICE")}
            >
              {t(
                `Chat directly with our professional traders throughout your whole trade. Our top priority is fulfilling all your doubts.`
              )}
            </OTCCard>
            <OTCCard
              color="#5633F1"
              title={t("DEEP")}
              subtitle={t("LIQUIDITY")}
            >
              {t(
                `Limitless trading from a wide range of countries on a variety of currencies (BTC, ETH, LTC, XLM, USDT y otras).`
              )}
            </OTCCard>
            <OTCCard
              color="#1919C1"
              title={t("IMMEDIATE")}
              subtitle={t("EXECUTION")}
            >
              {t("Trades are normally closed during the same day.")}
            </OTCCard>
          </CardGridContainer>
        </Center>
        <DescriptionInputTitle>
          <b>{t("Start trading OTC today")}</b> {t("with Bitennial")}
        </DescriptionInputTitle>
        <DescriptionInputText>
          {t(
            `Fill the contact form below and we will aget in touch with you as soon as possible`
          )}
        </DescriptionInputText>
        <Center>
          <InputsContainer>
            <TextInput
              fullWidth
              label={t("Name")}
              placeHolder={t("Enter Your Name")}
              onChange={(e) => handleChangeInput(e, "name")}
            />
            <TextInput
              fullWidth
              label={t("Last Name")}
              placeHolder={t("Enter Your Last Name")}
              onChange={(e) => handleChangeInput(e, "lastname")}
            />

            <TextInput
              fullWidth
              label={t("Email")}
              placeHolder={t("Enter Your Email")}
              onChange={(e) => handleChangeInput(e, "email")}
            />
            <TextInput
              fullWidth
              label={t("Skype ID")}
              placeHolder={t("Enter Your Skype ID")}
              onChange={(e) => handleChangeInput(e, "skype_id")}
            />

            <TextInput
              fullWidth
              label={t("Amount to trade (USD)")}
              placeHolder={t("Enter the Amount")}
              onChange={(e) => handleChangeInput(e, "amount")}
            />
            <TextInput
              fullWidth
              label={t("Cryptocurrency")}
              placeHolder={t("Enter the Name")}
              onChange={(e) => handleChangeInput(e, "crypto")}
            />
          </InputsContainer>

          <Radio
            style={{ marginTop: "7rem", paddingBottom: "2rem" }}
            options={[
              { name: t("Yes"), value: true },
              { name: t("No"), value: false },
            ]}
            label={t("DO YOU HAVE A BITENNIAL ACCOUNT?")}
            onChange={(e) => handleChangeInput(e, "bitennial_account")}
          />

          <div style={{ paddingBottom: "2rem" }}>
            <ReCAPTCHA sitekey="6LdAQuseAAAAAJyLv1DFA7o08d6yvEFVmBAsjc4H" />
          </div>

          <SimulateButton
            disabled={!areInputsCompleted()}
            type="squared"
            onClick={handleSendMessage}
          >
            {t("SEND NOW")}
          </SimulateButton>
        </Center>
      </TextViewWrapper>
    );
}
