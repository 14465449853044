import { AnimatePresence } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import useOutsideAlerter from "../../Hooks/ClickAwayListener";
import { StyledLabel } from '../TextInput/styles';
import { Option, OptionsContainer, PlaceHolder, SelectButton, SelectValue } from './styles';
import { useTranslation } from "react-i18next";

export default function Select({ label, placeHolder, type, fullWidth, options, style, onChange }) {
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const OptionContainerRef = useRef(null);
  const { t } = useTranslation();
    useOutsideAlerter(OptionContainerRef);

    const handleSelectOption = (optionKey) => {
        setSelected(optionKey);
        setOpen(false);
    };
  
    useEffect(() => {
      selected && onChange(selected);
    }, [selected]);
    


    return (
      <StyledLabel style={style} fullWidth={fullWidth}>
        <span style={{ marginLeft: 10 }}>{label}</span>
        <SelectButton
          isOpen={isOpen}
          whileHover={{
            backgroundColor: "#e2e2e2",
            transition: { duration: 0.1 },
          }}
          onClick={() => {
            setOpen((state) => !state);
          }}
        >
          {!selected ? (
            <PlaceHolder>{t(placeHolder)}</PlaceHolder>
          ) : (
            <SelectValue>{selected.label}</SelectValue>
          )}
        </SelectButton>
        <AnimatePresence>
          {isOpen ? (
            <OptionsContainer
              animate={{
                height: "auto",
                transition: {
                  duration: 0.1,
                },
              }}
              exit={{
                height: 0,
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
            >
              {options.map((option) => (
                <Option
                  whileHover={{
                    backgroundColor: "#e2e2e2",
                    transition: { duration: 0.3 },
                  }}
                  onClick={() => handleSelectOption(option)}
                >
                  {t(option.label)}
                </Option>
              ))}
            </OptionsContainer>
          ) : null}
        </AnimatePresence>
      </StyledLabel>
    );
}