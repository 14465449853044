import styled from "styled-components";
import {MOBILE_BREACKPOINT} from "../../utils/breakpoints"

export const OTCCardContainer = styled.div`
  width: calc(100% - 2);
  border: 2.04px solid white;
  border-radius: 32.67px;
  height: 15.3125em;
  font-size: 18px;
  padding: 0.7em 1.6875em;
`;

export const OTCTitle = styled.h3`
  color: white;
  font-size: 1.7em;
  text-transform: uppercase;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const OTCSubTitle = styled.h3`
  color: white;
  font-size: 1.7em;
  font-weight: 200;
  padding-bottom: 0;
  margin: 0;
  text-transform: uppercase;
`;
export const OTCText = styled.h3`
  color: white;
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 0;
  margin: 0;
  text-transform: uppercase;
  margin-top: 1em;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    text-transform: lowercase;
  }
`;