import Navbar from "../../components/Navbar";
import VideoWrapper from "../../components/Video";
import FirstVideoWrapper from "./FirstVideo";
import { useRef, useState, useEffect } from "react";
import SecondVideoWrapper from "./SecondVideo";
import ThirdVideoWrapper from "./ThirdVideo";
import FourthVideoWrapper from "./FourthVideo";
import InformedVideoWrapper from "./InformedVideo";
import useTimer from "../../Hooks/useTimer";
import useLockBodyScroll from "../../Hooks/useScrollLock";
import SummerVideoWrapper from "./SummerVideo";
import SwapVideoWrapper from "./SwapVideo";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import StakeVideoWrapper from "./StakeVideo";
import DownloadFromApp from "./MobileImage";
import deviceType from "../../utils/breakpoints";

const scrollToRef = (ref) => {
    if (ref.current) {
      window.scrollTo(0, ref.current.offsetTop);
    }
}

const ScrollComponent = styled.div`
  scroll-snap-type: y mandatory;
`

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function LandingView(props) {
  // Second video Refs
  const secondVideo = useRef(null);
  const [playSecondVideo, setPlaySecond] = useState(false);

  // Third video refs
  const thirdVideo = useRef(null);
  const [playThirdVideo, setPlayThird] = useState(false);

  // Fourth Video refs
  const fourthVideo = useRef(null);
  const [playFourthVideo, setPlayFourth] = useState(false);

  // Informed Video refs
  const informedVideo = useRef(null);
  const [playInformedVideo, setPlayIformed] = useState(false);

  // Summer Video refs
  const summerVideo = useRef(null);
  const [playSummerVideo, setPlaySummer] = useState(false);

  // Swap Video refs
  const swapeVideo = useRef(null);
  const [playSwapVideo, setPlaySwap] = useState(false);

  // Stake Video refs
  const stakeVideo = useRef(null);
  const [playStakeVideo, setPlayStake] = useState(false);

  const VIDEOS_AMOUNT = 6;
  const emptyArray = [];
  emptyArray[VIDEOS_AMOUNT - 1] = null;

  const [videosLoading, setVideosLoading] = useState(emptyArray);
  const [isLoading, setFinishLoading] = useState(!isSafari);

  const device = deviceType();
  const time = device !== "desktop" ? 100 : 5200;


  // Show Nav
  const showNav = useTimer(time, !isLoading);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bitennial - Home";
  }, []);

  const [videoScroll, setVideoScroll] = useState([]);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (videosLoading.every((item) => Boolean(item) === true))
      setFinishLoading(false);
  }, [videosLoading]);

  useEffect(() => {
    //Plays the video when scrolls reachs the top of it
    setVideoScroll([
      {
        ref: secondVideo,
        setPlay: setPlaySecond,
      },
      {
        ref: thirdVideo,
        setPlay: setPlayThird,
      },
      {
        ref: informedVideo,
        setPlay: setPlayIformed,
      },
      {
        ref: fourthVideo,
        setPlay: setPlayFourth,
      },
      {
        ref: summerVideo,
        setPlay: setPlaySummer,
      },
      {
        ref: swapeVideo,
        setPlay: setPlaySwap,
      },
      {
        ref: stakeVideo,
        setPlay: setPlayStake,
      },
    ]);
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    videoScroll.forEach((item, index) => {
      if (item.ref.current?.offsetTop - 200 <= offset) {
        item.setPlay(true);
        let newVideoData = [...videoScroll];
        newVideoData.splice(index, 1);
        setVideoScroll(newVideoData);
      }
    });
  }, [offset]);

  useLockBodyScroll(showNav);

  const handleEndVideo = (index) => {
    if (!isSafari) {
      setVideosLoading((currentState) => {
        let newState = [...currentState];
        newState[index] = true;
        return newState;
      });
    }
  }


  return (
    <ScrollComponent>
      <Loading show={isLoading} />
      <Navbar show={showNav} />
      <FirstVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(0)}
      />
      <SecondVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(1)}
        play={playSecondVideo}
        innerRef={secondVideo}
      />
      <ThirdVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(2)}
        play={playThirdVideo}
        innerRef={thirdVideo}
      />
      <InformedVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(3)}
        play={playInformedVideo}
        innerRef={informedVideo}
      />
      <FourthVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(4)}
        play={playFourthVideo}
        innerRef={fourthVideo}
      />
      <SummerVideoWrapper
        finishLoading={!isLoading}
        onLoad={() => handleEndVideo(5)}
        play={playSummerVideo}
        innerRef={summerVideo}
      />
      {device !== "mobile" && (
        <>
          <SwapVideoWrapper
            finishLoading={!isLoading}
            onLoad={() => handleEndVideo(6)}
            play={playSwapVideo}
            innerRef={swapeVideo}
            isMobile
            loop
          />
          <StakeVideoWrapper
            finishLoading={!isLoading}
            onLoad={() => handleEndVideo(7)}
            play={playStakeVideo}
            innerRef={stakeVideo}
            isMobile
            loop
          />
          <DownloadFromApp />
        </>
      )}
      <Footer />
      {/* <SwapVideoWrapper play={playSwapVideo} innerRef={swapeVideo} /> */}
    </ScrollComponent>
  );
}
