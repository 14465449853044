import styled from "styled-components";
import Button from "../../components/Button";
import {MOBILE_BREACKPOINT} from "../../utils/breakpoints"
import "typeface-roboto";


export const SimulateButton = styled(Button)`
    width: 60%;
    max-width: 368px;
    border-radius: 16px;
    height: 56px;
    font-family: 'Roboto';
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 37px;
  letter-spacing: -0.408px;
  max-width: 74%;
  color: #424141;
  margin-top: 2rem;
  margin-inline: 6rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    max-width: 90%;
    margin-inline: 2rem;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 74%;
  margin-top: 3rem;
`

export const CardGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  grid-gap: 3rem;
  margin-top: 4rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    grid-template-columns: 1fr;
  }
`;
export const OTCSubTitleText = styled.span`
  font-weight: 700;
  margin-top: "1rem";
  color: "#424141";
  text-align: "center";
  font-weight: "bold";
  max-width: 650;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    padding: 0 40px;
  }
`;