import styled from "styled-components";
import { Link as DomLink } from "react-router-dom";


export const Link = styled(DomLink)`
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin-left: ${(props) => props.ml};
`;

export const AHref = styled.a`
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin-left: ${(props) => props.ml};
`;
