import React from 'react';
import { Button, CTAButton } from './styles';

export default function index(props) {
  if (props.type == 'squared') {
    return (
      <CTAButton
        whileHover={!props.disabled ? {
          scale: 1.2,
          transition: { duration: .3 },
        }: null}
        whileTap={!props.disabled ? { scale: 0.9 } : null}
        {...props}
      />
    );  } else {
    return (
      <Button
        whileHover={{
          scale: 1.2,
          transition: { duration: .3 },
        }}
        whileTap={{ scale: 0.9 }}
        {...props}
      />
    );
  }
}
