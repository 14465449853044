import React, { useEffect, useRef, useState } from 'react';
import { Image, Video, VideoContainer } from './styles';

export default function VideoWrapper({
  src,
  play,
  autoPlay,
  onEnded,
  inverse,
  finishLoading,
  onLoad,
  isMobile,
  mobileStyles,
  loop,
  videoImage,
}) {
  const video = useRef(null);
  const [autoPlayVideo, setAutoPlay] = useState(true);

  useEffect(() => {
    if (play && video.current) {
      video.current.play();
    }
  }, [play]);

  // When video finish loading triggers the onLoad funcition
  useEffect(() => {
    if (video.current) {
      video.current.addEventListener("loadeddata", (e) => {
        if (video.current.readyState >= 3) {
          onLoad();
        }
      });
    }
  }, [video]);

  const handleEndVideo = () => {
    if (onEnded) onEnded();
    video.current.pause();
  };

  return (
    <>
      <VideoContainer>
        <Video
          onEnded={handleEndVideo}
          src={src}
          ref={video}
          autoPlay={autoPlayVideo}
          muted
          loop={loop}
          inverse={inverse}
          isMobile={isMobile}
          style={isMobile ? mobileStyles : null}
        />
      </VideoContainer>
      {videoImage &&
        <Image style={{backgroundImage: `url(${videoImage})`}} />
      }
    </>
  );
}
