import styled from "styled-components"
import Button from "../../components/Button"
import "typeface-roboto"
import { motion } from "framer-motion"
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"

export const SimulateButton = styled(Button)`
  width: 60%;
  max-width: 368px;
  border-radius: 16px;
  height: 56px;
  font-family: "Roboto";
`

export const Text = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 37px;
  letter-spacing: -0.408px;
  max-width: 74%;
  color: #424141;
  margin-top: 2rem;
`

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 74%;
  margin-top: 3rem;
`

export const FAQsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-top: 4rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    margin-top: 3rem;
    padding: 16px;
  }
`

export const AcordionContainer = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  padding-top: 0.3rem;
  display: flex;
  flex-direction: column;
  width: 634px;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 85%;
    margin-left: 4%;
  }
`

export const QuestionTitle = styled.h3`
  width: 100%;
  font-size: 1.3em;
  display: flex;
`

export const FAQsAnswer = styled(motion.div)`
  overflow: hidden;
`

export const FAQText = styled.span`
  font-size: 1.3em;
  display: flex;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    max-width: 343px;
    align-self: center;
  }
`
