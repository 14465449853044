import styled from "styled-components";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const Video = styled.video`
  height: ${({ isMobile }) => (isMobile ? "auto" : "100vh")};
  width: ${({ isMobile }) => (isMobile ? "auto" : "100%")};
  border-radius: ${({ isMobile }) => (isMobile ? "2.5rem" : "none")};
  object-fit: contain;
  object-position: center;
  transform: ${({ inverse }) => (inverse ? "scale(-1, 1)" : "scale(1, 1)")};
  object-fit: cover;
  max-width: ${({ isMobile }) => (isMobile ? "320px" : "auto")};
  margin-top: ${({ isMobile }) => (isMobile ? "9rem" : "0")};
  margin-bottom: ${({ isMobile }) => (isMobile ? "1rem" : "0")};

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    height: 90vh;
    width: 110%;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e0d6da;
  width: "100%";

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: none;
  }
`;

export const Image = styled.div`
  display: none;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    display: block;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;
