import { useLayoutEffect } from "react";

export default function useLockBodyScroll(show) {
    useLayoutEffect(
      () => {
        if ( !show ) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = 'initial'
        }
    }, [show] 
  );
}
