import React from "react";
import {
  CopyRightText,
  FooterContainer,
  FooterContentContainer,
  FooterMediaContainer,
  FooterNavigation,
  Logo,
  LogoContainer,
  LogoText,
  MediaIcon,
  MediaIconsContainer,
} from "./styles";
import BitennialWhiteLogo from "../../assets/icons/bitennialWhiteLogo.svg";
import { Wrapper } from "../Navbar/styles";
import styled from "styled-components";
import { Link, AHref } from "../Anchor/styles";
import { FlexBox } from "../Box/stlye";
import FacebookLogo from "../../assets/icons/facebook.svg";
import InstagramLogo from "../../assets/icons/instagram.svg";
import TwitterLogo from "../../assets/icons/twitter.svg";
import YoutubeLogo from "../../assets/icons/youtube.svg";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation();

  return (
    <FooterContainer removeScroll={props.removeScroll}>
      <Wrapper>
        <FooterContentContainer>
          <FlexBox>
            <LogoContainer>
              <Logo src={BitennialWhiteLogo} alt="Bitennial Logo" />
              <LogoText>{t("Unlimitless")}</LogoText>
              <LogoText>
                <b>{t("Community")}</b>
              </LogoText>
            </LogoContainer>
            <FooterNavigation>
              <Link to="/FAQs">{t("FAQ'S")}</Link>
              <Link to="/OTC">{t("OTC")}</Link>
              <Link to="/terms-and-conditions">{t("Terms & Conditions")}</Link>
              <Link to="/about-us/">{t("About Us")}</Link>
              <AHref href="/#swap">Swap</AHref>
              <Link to="/contact/">{t("Contact")}</Link>
              <AHref href="/#staking">Staking</AHref>
              <Link to="/PyP">{t("Privacy Policy")}</Link>
            </FooterNavigation>
          </FlexBox>
          <FooterMediaContainer>
            <CopyRightText>
              &copy; {t("2022 Bitennial, All Rights Reserved.")}
            </CopyRightText>
            <MediaIconsContainer>
              <MediaIcon src={FacebookLogo} />
              <MediaIcon src={TwitterLogo} />
              <MediaIcon src={InstagramLogo} />
              <MediaIcon src={YoutubeLogo} />
            </MediaIconsContainer>
          </FooterMediaContainer>
        </FooterContentContainer>
      </Wrapper>
    </FooterContainer>
  );
}
