import { motion } from "framer-motion";
import styled from "styled-components";

export const SelectButton = styled(motion.div)`
  width: 99%;
  border: 1.5px solid #424141;
  height: 50px;
  border-radius: 10px;
  font-size: 17px;
  text-align: left;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: ${({ isOpen }) => (isOpen ? "none" : "1.5px solid #424141")};
  border-end-end-radius: ${({ isOpen }) =>
    isOpen ? "0px" : "10px"};
  border-end-start-radius: ${({ isOpen }) =>
    isOpen ? "0px" : "10px"};

  &::placeholder {
    text-align: left;
    padding-left: 20px;
  }
`;

export const PlaceHolder = styled.span`
  margin-left: 1rem;
  font-weight: 400;
  color: #989797;
`;

export const SelectValue = styled.div`
  margin-left: 1rem;
  font-weight: 400;
`;

export const OptionsContainer = styled(motion.div)`
  background-color: #ffffff;
  position: absolute;
  width: 99%;
  z-index: 20;
  border: 1.5px solid #424141;
  border-radius: 10px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: 0px;
  overflow: hidden;
`;

export const Option = styled(motion.div)`
  width: 100%;
  height: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 2rem;
`;