import { motion } from "framer-motion";
import React from "react";
import { StyledInput, StyledLabel } from "./styles";

export default function TextInput({
  label,
  placeHolder,
  type,
  fullWidth,
  textArea,
  style,
  animate,
  initial,
  exit,
  onChange,
}) {
  return (
    <StyledLabel
      animate={animate}
      initial={initial}
      exit={exit}
      style={style}
      fullWidth={fullWidth}
    >
      <motion.span style={{ marginLeft: 10 }}>{label}</motion.span>
      <StyledInput
        onChange={onChange}
        textArea={textArea}
        style={{ marginTop: 10 }}
        placeholder={placeHolder}
        type={type || "text"}
      />
    </StyledLabel>
  );
}
