import React, { useEffect, useState } from "react"
import { Header, SubTitle } from "../../components/Header"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import VideoWrapper from "../Video"
import Button from "../Button"
import deviceType, { MOBILE_BREACKPOINT } from "../../utils/breakpoints"
import { useTranslation } from "react-i18next";

const LandingHeader = styled(Header)`
  position: relative;
  z-index: 2;
  margin-top: 0px;
  display: flex;
  width: "max-content";
  margin-left: ${({ textPosition }) =>
    textPosition === "right" ? "0%" : "30%"};

  @media (max-width: ${MOBILE_BREACKPOINT}) {
    justify-content: flex-end;
    text-align: center;
    font-size: 26px;
    margin-left: ${({ textPosition }) =>
      textPosition !== "right" ? "0%" : " 50%"};
    position: absolute;
    margin-top: 15%;
    margin-right: 0;
    max-width: 50%;
  }
`;

const InvestSubtitle = styled(SubTitle)`
  position: relative;
  z-index: 2;
  margin-top: 0px;
  display: flex;
  margin-left: ${({ textPosition }) =>
  textPosition === "right" ? "0%" : "30%"};
`

const AnimationContainer = styled.div`
  scroll-snap-align: start;
  position: relative;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    scroll-snap-align:initial;
  }
`

const ViewDemoButton = styled(Button)`
  margin-top: 30px;
`

const textAnimaton = (delay) => {
  return {
    transition: {
      delay: delay,
      type: "spring",
      stiffness: 100,
    },
    animate: {
      x: -100,
    },
  }
}

export default function VideoWrapperView({
  
  play,
  innerRef,
  textShowTime,
  textPosition,
  headers,
  subHeadings,
  videoSource,
  buttonLabel,
  inverse,
  finishLoading,
  onLoad,
  isMobile,
  loop,
  videoImage,
  id
}) {
  const [show, setShow] = useState(false)
  const { t } = useTranslation();
  const device = deviceType()
  
  useEffect(() => {
    if (play && finishLoading) {
      setTimeout(function () {
        setShow(true)
      }, textShowTime)
    }
  }, [play, finishLoading])

  let headerDelay = 0

  let mobileStyles;

  if (isMobile) {
    if (textPosition === 'left') { // mobile is on the right
      mobileStyles = {
        marginLeft: 'auto',
        marginRight: '15%'
      }
    } else {
      mobileStyles = {
        marginRight: "auto",
        marginLeft: "15%",
      };
    }
  }

  return (
    <AnimationContainer  id={id} ref={innerRef}>
      <AnimatePresence>
        {show && device !== "mobile" && (
          <motion.div
            style={{
              diplay: "flex",
              flexDirection: "column",
              width: textPosition === "left" ? "70%" : "max-content",
              justifyContent: "center",
              marginTop: "15%",
              position: "absolute",
              zIndex: 10,
              marginLeft:textPosition !== "left" ? "65%" : null,
            }}
          >
            {headers.map((text, index) => {
              headerDelay += 0.1;
              return (
                <LandingHeader
                  key={`${index}_landing_header_${text}`}
                  textPosition={textPosition}
                  transition={{
                    delay: headerDelay,
                    type: "spring",
                    stiffness: 120,
                  }}
                  animate={{ x: -100 }}
                >
                  {t(text)}
                </LandingHeader>
              );
            })}

            <motion.div
              {...textAnimaton(headers.length * 0.1)}
              style={{ marginTop: 30 }}
            >
              {subHeadings.map((text, index) => {
                return (
                  <InvestSubtitle
                    key={`${index}_subtitle_${text}`}
                    textPosition={textPosition}
                  >
                    {t(text)}
                  </InvestSubtitle>
                );
              })}
              {buttonLabel ? (
                <ViewDemoButton type="squared">{buttonLabel}</ViewDemoButton>
              ) : null}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {device == "mobile" && (
        <LandingHeader textPosition={textPosition}>
          {headers.join(" ")}
        </LandingHeader>
      )}

      <VideoWrapper
        inverse={inverse}
        src={videoSource}
        play={play}
        autoPlay={false}
        onLoad={onLoad}
        isMobile={isMobile}
        mobileStyles={mobileStyles}
        loop={loop}
        videoImage={videoImage}
      />
    </AnimationContainer>
  );
}
