import styled from "styled-components";
import Button from "../../components/Button";
import "typeface-roboto";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints";

export const CryptoWrapper = styled.div`
  display: grid;
  grid-template-columns: 180px 180px 180px;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  align-items: center;
  padding: 3rem;
  justify-content: space-around;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    grid-template-columns: 180px 180px;
    grid-column-gap: 0rem;
    grid-row-gap: 2rem;
    align-items: center;
    justify-items: center;
    padding: 0;
  }
`;

export const SimulateButton = styled(Button)`
  width: 60%;
  max-width: 368px;
  border-radius: 16px;
  height: 56px;
  font-family: "Roboto";
  margin-top: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    width: 48%;
    font-size: 1em;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  min-height: 15rem;
`

export const EarningsCard = styled.div`
  margin-top: 2rem;
  background-color: rgba(86, 51, 241, .7);
  min-width: 360px;
  padding: 1rem 2rem;
  border-radius: 33px;
  min-height: 250px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    min-width: 130px;
    min-height: 100px;
    width: 220px;   
    height: 180px;
    font-size: 0.7em;
  }
`;

export const EarningsTitle = styled.h3`
  color: white;
  font-size: 26px;
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 2em;
    margin-top: 10px;
  }
`;

export const EarnignsDescription = styled.span`
  color: white;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: bold;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 1.3em;
  }
`;

export const EarnignsResult = styled.span`
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: bold;
  margin-left: 1rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 1.3em;
  }
`;
