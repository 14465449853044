import React, { useEffect, useState } from 'react'
import TextViewWrapper from '../../components/TextView';
import backgroundAboutUs from "../../assets/image/backgroundAboutUs.png";
import { LandingHeader } from '../Landing/FirstVideo';
import { ConditionContainer, ConditionLink, ConditionsNavigation, ConditionText, ConditionTitle, Numeration } from './styles';
import styled from 'styled-components';
import { FlexBox } from '../../components/Box/stlye';
import { useTranslation } from "react-i18next";
import { MOBILE_BREACKPOINT } from "../../utils/breakpoints"
import { TermsAndConditionsData } from './termsText';


const Center = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Header = styled(LandingHeader)`
  font-size: 3rem;
  @media (max-width: ${MOBILE_BREACKPOINT}) {
    font-size: 2.5em;
  }
`;

export default function TermsAndConditions() {
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = t("Bitennial - Terms & Conditions");
    }, []);
  
    return (
      <TextViewWrapper>
        <Center>
          <Header>{t("Terms and Conditions")}</Header>
          <ConditionsNavigation>
            {TermsAndConditionsData.map((condition) => (
              <li>
                <ConditionLink href={"#" + condition.title}>
                  {condition.title}
                </ConditionLink>
              </li>
            ))}
          </ConditionsNavigation>
          <ConditionContainer>
            {TermsAndConditionsData.map((condition, index) => {
              let counter = 0;
              return (
                <section id={condition.title} style={{paddingBottom: '2rem'}}>
                  <ConditionTitle>{condition.title}</ConditionTitle>
                  {condition.conditions.map((item, i) => {
                    if (!item.hideNumeration) {
                      counter += 1;
                    }
                    return (
                      <div style={{ display: "flex" }}>
                        <ConditionText>
                          <Numeration>
                            {!item.hideNumeration && `${index + 1}.${counter}.`}{" "}
                            {item.title}
                          </Numeration>{" "}
                          {item.content}
                        </ConditionText>
                      </div>
                    );
                  })}
                </section>
              );})}
          </ConditionContainer>
        </Center>
      </TextViewWrapper>
    );
}
