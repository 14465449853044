import React from 'react'
import { DownloadButtonContent, DownloadButtonsContainer, DownloadTextContainer, Image, MobileContainer, SoftwareIcon } from './styles';
import DownloadImage from '../../../assets/image/multipleMobiles.png'
import { SubTitle } from '../../../components/Header';
import { motion, AnimatePresence } from "framer-motion";
import Button from '../../../components/Button';
import AppleIcon from '../../../assets/icons/apple.png'
import PlayStore from "../../../assets/icons/playstore.png";


const DownloadIcon = ({ icon, label }) => {
  return (
    <DownloadButtonContent type="squared">
      <SoftwareIcon src={icon} alt={label} />
      {label}
    </DownloadButtonContent>
  );
};

export default function DownloadFromApp() {
    return (
      <MobileContainer>
        <DownloadTextContainer style={{ marginLeft: "10%" }}>
          <SubTitle>Coming soon our Mobile app in...</SubTitle>
          <DownloadButtonsContainer>
            <DownloadIcon icon={AppleIcon} label={"App Store"} />
            <DownloadIcon icon={PlayStore} label={"Google Play"} />
          </DownloadButtonsContainer>
        </DownloadTextContainer>
        <Image src={DownloadImage} alt={"Download App Image"} />
      </MobileContainer>
    );
}
