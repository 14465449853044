import React from 'react'
import useTimer from '../../Hooks/useTimer';
import Navbar from '../Navbar';
import { Background, TransparetModal, ViewImage } from './style';
import BackgroundColor from '../../assets/image/background.png'
import Footer from '../Footer';

function ImageBackground({ image, children }) {
    const showNav = useTimer(500, true);

    return (
        <div style={{ height: '100%'}}>
            <div>
                <Navbar show={showNav}/>
            </div>
            <Background style={{ backgroundImage: `url(${BackgroundColor})` }}>
                <TransparetModal>
                    {children}
                </TransparetModal>    
                <ViewImage src={image} />
            </Background>
            <Footer removeScroll/>
        </div>
    );
}


export default function TextViewWrapper({image, children}) {
    return <ImageBackground image={image}>
        { children }
    </ImageBackground>;
}
