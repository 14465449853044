import React from 'react'
import useLockBodyScroll from '../../Hooks/useScrollLock';
import { InnerCircle, LoadingAnimation, LoadingContainer } from './styles';


const bounceTransition = {
  delay: 0,
  y: {
    duration: 0.4,
    yoyo: Infinity,
    ease: "easeOut",
    repeatDelay: 1,
  },
  backgroundColor: {
    duration: 0.4,
    yoyo: Infinity,
    ease: "easeOut",
    repeatDelay: 1,
  },
};

export default function Loading({ show }) {
    
    return (
      <LoadingContainer animate={!show && {opacity: 0}} transition={{duration: 1}}>
        <LoadingAnimation
          transition={bounceTransition}
          animate={{
            y: ["100%", "-100%", "100%"],
            backgroundColor: ["#ff6699", "#6666ff"],
          }}
        ></LoadingAnimation>
        <LoadingAnimation
          transition={{ ...bounceTransition, delay: 0.3 }}
          animate={{
            y: ["100%", "-100%", "100%"],
            backgroundColor: ["#ff6699", "#6666ff"],
          }}
        ></LoadingAnimation>
        <LoadingAnimation
          transition={{ ...bounceTransition, delay: 0.6 }}
          animate={{
            y: ["100%", "-100%", "100%"],
            backgroundColor: ["#ff6699", "#6666ff"],
          }}
        ></LoadingAnimation>{" "}
        <LoadingAnimation
          transition={{ ...bounceTransition, delay: 0.9 }}
          animate={{
            y: ["100%", "-100%", "100%"],
            backgroundColor: ["#ff6699", "#6666ff"],
          }}
        ></LoadingAnimation>
      </LoadingContainer>
    );
}
