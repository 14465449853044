import React, { useEffect, useState } from "react";
import StakeVideo from "../../../../src/assets/landingVideos/stake.mp4";
import VideoWrapperView from "../../../components/VideoWrapperView";

export default function StakeVideoWrapper(props) {
  return (
    <VideoWrapperView
      id="staking"
      videoSource={StakeVideo}
      textPosition="right"
      textShowTime={100}
      headers={["Crypto Earn"]}
      subHeadings={[
        "- Earn passive income by staking your crypto in DeFi",
        "- Choose your crypto, select amount and time period ",
        "and start earning.",
        "- Monitor your investment in real time",
      ]}
      {...props}
    ></VideoWrapperView>
  );
}
