import React, { useEffect, useState } from 'react'
import TextViewWrapper from '../../components/TextView';
import SimulateImage from "../../assets/image/simulateEarnings.png";
import { LandingHeader } from '../Landing/FirstVideo';
import BTCIcon from '../../assets/icons/btc.png'
import ETHIcon from "../../assets/icons/eth.png";
import USDTIcon from "../../assets/icons/usdt.png";
import DAIIcon from "../../assets/icons/dai.png";
import USDCIcon from "../../assets/icons/usdc.png";
import {
  CryptoWrapper,
  InputsContainer,
  SimulateButton,
  EarningsCard,
  EarningsTitle,
  EarnignsDescription,
  EarnignsResult,
} from "./styles";
import CryptoCard from '../../components/CryptoCard';
import styled from 'styled-components';
import { FlexBox } from '../../components/Box/stlye';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from "react-i18next";

const FETCH_FROM =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false";

const cryptoData = [
  { code: "BTC", value: "41143.43", icon: BTCIcon },
  { code: "ETH", value: "2739.43", icon: ETHIcon },
  { code: "USDT", value: "1.00", icon: USDTIcon, isStable: true },
  { code: "DAI", value: "1.00", icon: DAIIcon, isStable: true },
  { code: "USDC", value: "1.00", icon: USDCIcon, isStable: true },
];

const SELECT_OPTIONS = [
  { key: "flex", label: "Flex" },
  { key: "f_m3_rate", label: "3 Months" },
  { key: "f_m6_rate", label: "6 Months" },
  { key: "f_m12_rate", label: "1 Year" },
  { key: "more", label: "More than an Year" },
];

const Center = styled(FlexBox)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export default function SimulateEarnings() {
    const [selected, setSelected] = useState(null);
    const [cryptoAPI, setCryptoData] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [stakingRates, setStakingRates] = useState(null);
    const [amount, setAmount] = useState(0);
    const [results, setResults] = useState(null);
    const [flexAmountOfDays, setAmountOfDays] = useState(0);  
    const [amountOfYears, setAmountOfYears] = useState(0);
  
  
    const { t } = useTranslation();

    const handleLoadData = () => {
      fetch(FETCH_FROM)
      .then((res) => res.json())
      .then((data) => setCryptoData(data));
    }

    const handleChangeAmountOfDates = (e) => {
      const today = new Date();

      const dateData = e.currentTarget.value.split('-')

      const date = new Date(`${dateData[1]}/${dateData[2]}/${dateData[0]}`);
      
      const timeDifference =
        (date.getTime() - today.getTime());
      
      setAmountOfDays(timeDifference / (1000 * 3600 * 24))

    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = 'Bitennial - Simutale Earnings';
      fetch(process.env.REACT_APP_API + "staking/api/staking_rates")
        .then((res) => res.json())
        .then((data) => setStakingRates(data));
      handleLoadData();
    }, [])
  
    // useEffect(() => {
    //   setTimeout(function () {
    //     handleLoadData();
    //   }, 3000)
    // }, [cryptoAPI]);
  
    const handleChangeSelect = (key) => {
      setSelectedTime(key);
      setResults(null);
    }
  
  
    const handleChangeAmount = (e) => {
      setAmount(e.currentTarget.value);
      setResults(null);
    }
  
    const handleSimulate = () => {
      let selectedCurrencyRate = stakingRates.filter((stakingRate) => stakingRate.currency === selected)[0];

      if (selectedTime === "flex") {
        setResults({
          amount: selectedCurrencyRate.fc_rate ** flexAmountOfDays * amount,
          anual: selectedCurrencyRate.c_rate,
        });
        return;
      } 

      if (selectedTime === 'more') {
        setResults({
          amount: selectedCurrencyRate.f_m12_rate ** amountOfYears * amount,
          anual: selectedCurrencyRate.m12_rate,
        });
        return;
      }

      const monthKey = selectedTime.split('f_')[1];

      setResults({
        amount: selectedCurrencyRate[selectedTime] * amount,
        anual: selectedCurrencyRate[monthKey],
      });
    };
    
    return (
      <TextViewWrapper image={SimulateImage}>
        <LandingHeader>{t("Simulate your earnings")}</LandingHeader>
        <Center>
          <CryptoWrapper>
            {cryptoData.map((item) => (
              <CryptoCard
                image={item.icon}
                code={item.code}
                cryptoAPI={cryptoAPI}
                stable={item.isStable}
                setSelection={setSelected}
                selected={selected}
              />
            ))}
          </CryptoWrapper>
          <InputsContainer>
            <TextInput
              label={t("Amount")}
              type="number"
              placeHolder={t("Enter a Capital to Invest")}
              onChange={handleChangeAmount}
            />
            <Select
              style={{ marginBlock: "1rem" }}
              onChange={({ key, label }) => handleChangeSelect(key)}
              options={SELECT_OPTIONS}
              label={t("Time Limit")}
              placeHolder={t("Select")}
            />
            <AnimatePresence>
              {selectedTime === "flex" ? (
                <TextInput
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  label={t("Completion of investment")}
                  type="date"
                  placeHolder={t("Select date")}
                  onChange={handleChangeAmountOfDates}
                />
              ) : null}
              {selectedTime === "more" ? (
                <TextInput
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  label={t("Amount of Years")}
                  type="number"
                  placeHolder={t("Pick an amount of years")}
                  onChange={(e) => setAmountOfYears(e.currentTarget.value)}
                />
              ) : null}
            </AnimatePresence>
          </InputsContainer>
          <SimulateButton onClick={handleSimulate} type="squared">
            {t("SIMULATE NOW")}
          </SimulateButton>
          {results && (
            <>
              <EarningsCard>
                <EarningsTitle>{t("Your earnings:")}</EarningsTitle>
                <div>
                  <EarnignsDescription>
                    {t("You Will Win ") + `(${selected})`}:
                  </EarnignsDescription>
                  <EarnignsResult>
                    {(results.amount - amount).toFixed(6)}
                    <span style={{ marginLeft: "1rem" }}>{selected}</span>
                  </EarnignsResult>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <EarnignsDescription>
                    {t("You Will Win ") + `(%)`}:
                  </EarnignsDescription>
                  <EarnignsResult>
                    {((results.amount * 100) / amount - 100).toFixed(4)}%
                  </EarnignsResult>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <EarnignsDescription>
                    {t("Annual return (APY)")}:
                  </EarnignsDescription>
                  <EarnignsResult>{results.anual}%</EarnignsResult>
                </div>
              </EarningsCard>
            </>
          )}
        </Center>
      </TextViewWrapper>
    );
}

