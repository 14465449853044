
import styled from "styled-components";

export const RadioContainer = styled.form`
    display: grid;
    justify-content: center;
    align-items:center;
`;

export const RadioLabel = styled.span`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #5633f1;
  padding-bottom: 1rem;
`;

export const RadioButtonsContaner = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const RadioInput = styled.input`
  border: 0px;
  width: 2rem;
  height: 2em;
  
  &:checked:after {
    width: 22px;
    height: 22px;
    border-radius: 15px;
    top: 0px;
    left: 3px;
    position: relative;
    background-color: #424141;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #5633f1;
  }
`;