import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledInput = styled(motion.input)`
  width: 98%;
  border: 1.5px solid #424141;
  height: ${({ textArea }) => (textArea ? "150px" : "50px")};
  border-radius: 10px;
  font-size: 17px;
  color: #424141;
  text-align: ${({ textArea }) => (textArea ? "left" : "center")};

  &::placeholder {
    text-align: left;
    padding-left: 20px;
  }
`;

export const StyledLabel = styled(motion.label)`
  width: 100%;
  max-width: ${({fullWidth})=> fullWidth ? '100%' : '360px'};
  color: #424141;
  font-weight: 500;
  font-size: 17px;
  position: relative;
`;