import React from 'react'
import { OTCCardContainer, OTCSubTitle, OTCText, OTCTitle } from './styles';

export default function OTCCard({title, subtitle, children, color}) {
  return (
    <OTCCardContainer style={{ backgroundColor: color }}>
      <OTCTitle>
        <b>{title}</b>
      </OTCTitle>
      <OTCSubTitle>{subtitle}</OTCSubTitle>
      <OTCText>
        {children}
      </OTCText>
    </OTCCardContainer>
  );
}
